import type { FC } from 'react';
import { Global } from '@emotion/react';
import noop from '../../../../shared/utils/noop.ts';
import { globalCSS } from '../../../../styles/global.style.ts';
import ErrorContent from '../../ErrorContent/ErrorContent.tsx';

const ErrorFallbackGlobal: FC<{ resetError?: () => void }> = ({ resetError = noop }) => {
  return (
    <>
      <Global styles={globalCSS} />
      <ErrorContent button={{
        label: 'Try to Continue',
        onClick: resetError,
      }}
      />
    </>
  );
};

export default ErrorFallbackGlobal;
