import type { FC } from 'react';
import type { PolicyData } from '../../../components/CollectChartData/CollectChartData.type.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import type { ReplacementCostHistoryProps } from './ReplacementCostChart.type.ts';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import useReplacementCostHistoryQuery from '../../../api/history/use-replacement-cost-history-query.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import useRecentPolicyQuery from '../../../api/recent-policy/use-recent-policy-query.ts';
import CollectChartData from '../../../components/CollectChartData/CollectCharData.tsx';
import { getAllowedKeysToUpdate } from '../../../components/CollectChartData/CollectCharData.util.ts';
import InfoPanel from '../../../components/InfoPanel/InfoPanel.tsx';
import InfoRequestTooltip from '../../../components/InfoRequestTooltip/InfoRequestTooltip.tsx';
import { TEST_ID } from '../../../constants/test-id.ts';
import useResponsive from '../../../hooks/use-responsive.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import {
  addressCSS,
  chartCSS,
  chartHeightCSS,
  hintCSS,
  historyWrapperCSS,
  notificationCSS,
  titleCSS,
} from '../../../styles/chart.style.ts';
import { pageInfoPanelCSS } from '../../../styles/layout.style.ts';
import { AnalyticPageKey } from '../../../types/analytics.type.ts';
import { HistoryDataStatuses } from '../../../types/history.type.ts';
import { QuotingStatus } from '../../../types/quote.type.ts';
import { getChartState, transformReplacementCostData } from '../../../utils/chart.util.ts';
import ReplacementCostGraph from '../ReplacementCostGraph/ReplacementCostGraph.tsx';
import { CHART_DATA_SET } from '../ReplacementCostGraph/ReplacementCostGraph.utils.ts';
import { getInfoPanelContent } from './ReplacementCostHistory.utils.ts';

const ReplacementCostHistory: FC<ReplacementCostHistoryProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData, isLoading: accountQueryIsLoading } = useAccountQuery(gid);
  const {
    data: quotesData,
    isLoading: quotesQueryIsLoading,
  } = useQuotesQuery(gid, accountData?.account.data_collection_completed);
  const {
    data: historyData,
    isLoading: historyQueryIsLoading,
  } = useReplacementCostHistoryQuery(gid, quotesData?.quotes_request_status === QuotingStatus.Finished);
  const { data: recentPolicyData } = useRecentPolicyQuery(gid);

  const [showCollectData, setShowCollectData] = useState(false);
  const [enableCollectDataFeature, setEnableCollectDataFeature] = useState(false);

  const { isDesktop } = useResponsive();

  const address = accountData?.account.address ? accountData.account.address.line1 : '';

  const chartState = getChartState(
    quotesData?.quotes_request_status,
    accountData,
    quotesData,
    historyData,
  );

  const infoPanelContent = getInfoPanelContent(historyData);

  const dwellingCoverage = recentPolicyData?.person_policy.coverages?.find(coverage => coverage.key === 'dwelling');

  const policyData: PolicyData = useMemo(() => {
    return {
      limit_value: dwellingCoverage?.limit_value || null,
      expiration_date: recentPolicyData?.person_policy.expiration_date,
      premium: recentPolicyData?.person_policy.premium,
    };
  }, [recentPolicyData?.person_policy, dwellingCoverage?.limit_value]);

  const editableFields = useMemo(() => getAllowedKeysToUpdate(policyData, {
    ...recentPolicyData?.person_policy.change_sources,
    limit_value: dwellingCoverage?.change_sources?.limit_value,
  }), [policyData, recentPolicyData?.person_policy.change_sources, dwellingCoverage?.change_sources]);

  useEffect(() => {
    const enableDataEdit = !!editableFields.length;

    if (enableDataEdit && chartState === HistoryDataStatuses.Ready) {
      setShowCollectData(!Object.values(policyData).every(value => !!value));
      setEnableCollectDataFeature(true);
    }
  }, [chartState, editableFields, recentPolicyData?.person_policy.change_sources, policyData]);

  const getContent = () => {
    switch (chartState) {
      case HistoryDataStatuses.Ready:
        return (
          <div css={chartCSS(false)}>
            <ReplacementCostGraph data={transformReplacementCostData(historyData!.history)} hidePreview={false} />
            <div css={hintCSS}>
              Hint: {isDesktop ? 'Hover' : 'Tap'} over graph to see data per year.
            </div>

            {infoPanelContent && (
              <InfoPanel
                customCSS={pageInfoPanelCSS}
                {...infoPanelContent}
              />
            )}
          </div>
        );
      case HistoryDataStatuses.Pending:
        return (
          <div css={chartCSS()}>
            <ReplacementCostGraph data={transformReplacementCostData(CHART_DATA_SET.history)} />
            <div css={notificationCSS}>
              <h3>Almost There!</h3>
              <p>We are still fetching quotes for your home. We will update you when the process is complete.</p>
            </div>
          </div>
        );
      case HistoryDataStatuses.Unknown:
        return (
          <div css={chartCSS()}>
            <ReplacementCostGraph data={transformReplacementCostData(CHART_DATA_SET.history)} />
            <InfoRequestTooltip page={AnalyticPageKey.ReplacementCost} />
          </div>
        );
    }
  };

  if (accountQueryIsLoading || quotesQueryIsLoading || historyQueryIsLoading) {
    return (
      <>
        <SkeletonLoader width={75} absoluteHeight={32} />
        <SkeletonLoader width={65} marginTop={9} marginBottom={24} />
        <SkeletonLoader width={100} absoluteHeight={243} />
      </>
    );
  }

  return (
    <div css={[historyWrapperCSS, customCSS]}>
      <div css={titleCSS}>Suggested vs. Current Replacement Cost</div>
      <p css={addressCSS} data-testid={TEST_ID.ADDRESS}>
        <span>🏠 {address}</span>
        {
          enableCollectDataFeature
          && <Button variant="text" onClick={() => setShowCollectData(true)}>Update Policy Info</Button>
        }
      </p>
      {showCollectData && accountData && (
        <div css={[chartCSS(), chartHeightCSS]}>
          <CollectChartData
            editableFields={editableFields}
            page={AnalyticPageKey.RateReview}
            policyData={policyData}
            personGid={accountData.account.person_gid}
            policyGid={recentPolicyData?.person_policy.gid}
            onClose={() => setShowCollectData(false)}
            assetGid={accountData.account.asset_gid}
          />
        </div>
      )}
      {getContent()}
    </div>
  );
};

export default ReplacementCostHistory;
