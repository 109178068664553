import type { FC } from 'react';
import type { ListLoadingSkeletonProps } from './ListLoadingSkeleton.type';
import SkeletonLoader from '../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import { containerCSS } from './ListLoadingSkeleton.style';

const ListLoadingSkeleton: FC<ListLoadingSkeletonProps> = ({ customCSS, numberOfItems }) => {
  const items = Array.from({ length: numberOfItems }, (_, i) => i);

  return (
    <div css={[containerCSS, customCSS]}>
      {items.map(key => (
        <SkeletonLoader
          key={key}
          absoluteHeight={40}
          width={100}
        />
      ))}
    </div>
  );
};

export default ListLoadingSkeleton;
