import type { FC } from 'react';

import type { ProposalHeaderProps } from './ProposalHeader.type';
import useSoldCustomersCountQuery from '../../../api/proposal/use-sold-customers-count-query';
import EmailIcon from '../../../assets/svg/email.svg?react';
import PhoneIcon from '../../../assets/svg/phone.svg?react';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import SIRV_IMAGES from '../../../constants/sirv-images';
import THEME from '../../../styles/theme';
import ProposalPresenceIndicator from '../ProposalPresenceIndicator/ProposalPresenceIndicator';
import {
  agentBlockCSS,
  agentContactCSS,
  agentContactItemCSS,
  agentInfoCSS,
  containerCSS,
  insuranceImageCSS,
  mainBodyCSS,
  paragraphCSS,
} from './ProposalHeader.style';

const ProposalHeader: FC<ProposalHeaderProps> = ({
  proposal,
  refetchProposal,
  isOpenedFromAmp,
  state,
  agentContactInfo,
}) => {
  const { data: soldCustomersCount } = useSoldCustomersCountQuery({ agentGid: proposal?.agency_user_gid });
  const newAgentGreating = `I’m ${agentContactInfo.first_name}, your ${state}
  licensed insurance advisor — here to help protect your most important belongings. `;

  const experiencedAgentGreating = `I’m ${agentContactInfo.first_name}, your ${state} licensed insurance advisor.
                I’ve helped ${soldCustomersCount} customers protect their most important belongings.`;

  return (
    <ProposalCard backgroundColor={THEME.color.azure95}>
      {isOpenedFromAmp && <ProposalPresenceIndicator proposal={proposal} refetchProposal={refetchProposal} />}
      <div css={containerCSS}>
        <p css={paragraphCSS}>Review your insurance offer</p>
        <div css={mainBodyCSS}>
          <div css={agentBlockCSS}>
            <img src={SIRV_IMAGES.agent} width="64" height="60" alt="Agent photo placeholder" />
            <div css={agentInfoCSS}>
              <p>{soldCustomersCount && soldCustomersCount > 200 ? experiencedAgentGreating : newAgentGreating}</p>
              <div css={agentContactCSS}>
                <div css={agentContactItemCSS}>
                  <PhoneIcon />
                  {agentContactInfo.phone_number}
                </div>
                <div css={agentContactItemCSS}>
                  <EmailIcon />
                  {agentContactInfo.email}
                </div>
              </div>
            </div>
          </div>
          <img
            src={SIRV_IMAGES.insurance}
            width="246"
            height="118"
            alt="Home and auto insurance"
            css={insuranceImageCSS}
          />
        </div>
      </div>
    </ProposalCard>
  );
};

export default ProposalHeader;
