import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

function useScrollToBlockOnInit() {
  const location = useLocation();
  const isScrolled = useRef(false);

  const elementId = location.state?.scrollToId as string;

  useEffect(() => {
    const element = document.getElementById(elementId);
    let timer: NodeJS.Timeout;

    if (element && !isScrolled.current) {
      isScrolled.current = true;
      window.history.replaceState({}, '');

      timer = setTimeout(() => {
        window?.scrollTo({ behavior: 'smooth', top: element.offsetTop - 100 });
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [elementId]);
}

export default useScrollToBlockOnInit;
