import type { z } from 'zod';
import type { QuoteCarrierSchema, QuoteCoverageSchema, QuoteSchema, QuotesResponseSchema, SuggestedQuoteResponseSchema } from '../api/quotes/quote.schema.ts';

export enum PolicyType {
  Home = 'home',
  Auto = 'auto',
  Condo = 'condo',
  Fire = 'fire',
  Renters = 'renters',
}

export enum InsurableInterest {
  RealProperty = 'real_property',
  Vehicle = 'vehicle',
}

export enum CoverageKey {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  LossOfUse = 'loss_of_use',
  PersonalLiability = 'personal_liability',
  MedicalPayments = 'medical_payments',
  HouseholdFurnishings = 'household_furnishings',
  FairRentalValue = 'fair_rental_value',

  Hurricane = 'hurricane',
  WindHail = 'wind_hail',
  WaterBackup = 'water_backup',
  Earthquake = 'earthquake',
  Flood = 'flood',
  ExtendedReplacementCost = 'extended_replacement_cost',
  ReplacementCostOnContents = 'replacement_cost_on_contents',
  ServiceLine = 'service_line',
  EquipmentBreakdown = 'equipment_breakdown',
  Foundation = 'foundation',
  FunctionalReplacementCost = 'functional_replacement_cost',

  BiPerPerson = 'bodily_injury_per_person',
  BiPerAccident = 'bodily_injury_per_accident',
  PropertyDamage = 'property_damage',
  UninsuredMpd = 'uninsured_motorist_property_damage',
  UninsuredMbiPerPerson = 'uninsured_motorist_bodily_injury_per_person',
  UninsuredMbiPerAccident = 'uninsured_motorist_bodily_injury_per_accident',
  UnderinsuredMbiPerPerson = 'underinsured_motorist_bodily_injury_per_person',
  UnderinsuredMbiPerAccident = 'underinsured_motorist_bodily_injury_per_accident',
  Comprehensive = 'comprehensive',
  Collision = 'collision',
  Towing = 'towing',
  FullGlass = 'full_glass',
  RentalPerDay = 'rental_per_day',
  RentalMaxAmount = 'rental_max_amount',
  PersonalInjuryProtection = 'personal_injury_protection',

  PersonalInjury = 'personal_injury',
  OrdinanceOrLaw = 'ordinance_or_law',
  LossAssessment = 'loss_assessment',
}

export enum QuoteKind {
  Rc1 = 'rc1',
  Rc2 = 'rc2',
  NonIntegrated = 'non_integrated',
}

export enum LimitType {
  MoneyLimit = 'money_limit',
  MonthlyLimit = 'monthly',
  OtherLimit = 'other',
}

export enum DeductibleType {
  Flat = 'flat',
  Percentage = 'percentage',
}

export enum QuotingStatus {
  Pending = 'pending',
  Finished = 'finished',
}

export type Quote = z.infer<typeof QuoteSchema>;
export type SuggestedQuoteResponse = z.infer<typeof SuggestedQuoteResponseSchema>;
export type QuotesResponse = z.infer<typeof QuotesResponseSchema>;
export type QuoteCoverage = z.infer<typeof QuoteCoverageSchema>;
export type QuoteCarrier = z.infer<typeof QuoteCarrierSchema>;
