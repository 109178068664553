import type { FC } from 'react';
import type { QuestionProps, SpecificQuestionProps } from './Question.type';

import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import AddressQuestion from './AddressQuestion/AddressQuestion';
import DateInputQuestion from './DateInputQuestion/DateInputQuestion';
import EmailQuestion from './EmailQuestion/EmailQuestion';
import PhoneQuestion from './PhoneQuestion/PhoneQuestion';
import { QuestionType } from './Question.type';
import TextInputQuestion from './TextInputQuestion/TextInputQuestion';

function getQuestionByType(type: QuestionType = QuestionType.Text): FC<SpecificQuestionProps> {
  switch (type) {
    case QuestionType.Date:
      return DateInputQuestion;
    case QuestionType.Email:
      return EmailQuestion;
    case QuestionType.Phone:
      return PhoneQuestion;
    case QuestionType.Address:
      return AddressQuestion;
    case QuestionType.Text:
    default:
      return TextInputQuestion;
  }
}

const Question: FC<QuestionProps> = ({ type, ...props }) => {
  const QuestionComponent = getQuestionByType(type);
  const { clearErrors } = useFormContext();

  /* Since we use `shouldUnregister: false`, there is a possibility
    to trigger validation and then unmount that field.
    Without cleanup formState will be invalid. */
  useEffect(() => {
    return () => {
      clearErrors(props.questionKey);
    };
  }, [clearErrors, props.questionKey]);

  return <QuestionComponent {...props} />;
};

export default Question;
