import type { FC } from 'react';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import type { FactorsListProps } from './FactorsList.type';
import { useParams } from 'react-router';
import useScoreQuery from '../../api/score/use-score-query.ts';
import useAnalytics from '../../hooks/use-analytics.ts';
import useDpNavigate from '../../hooks/use-dp-navigate.ts';
import Button from '../../shared/components/Button/Button.tsx';
import { AnalyticLocation } from '../../types/analytics.type.ts';
import { DpCta } from '../../types/digital-profile.type.ts';
import FactorItem from '../FactorItem/FactorItem.tsx';
import ListLoadingSkeleton from '../ListLoadingSkeleton/ListLoadingSkeleton.tsx';
import { factorCSS, factorsContainerCSS } from './FactorsList.style.ts';

const FactorsList: FC<FactorsListProps> = ({ customCSS, page, scoreType, noFactorsButtonLabel }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: scoreData } = useScoreQuery(gid, scoreType);
  const navigateToDp = useDpNavigate();
  const { track } = useAnalytics();

  const factorsEmptyState = scoreData?.score.factors.length
    && !scoreData.score.factors.some(f => f.display_value || f.score);

  const handleActionClick = () => {
    track('Unlock Portal Data Requested', {
      page,
      location: AnalyticLocation.FactorsList,
    });
    navigateToDp(DpCta.Quotes, page);
  };

  if (!scoreData) {
    return <ListLoadingSkeleton customCSS={customCSS} numberOfItems={5} />;
  }

  return (
    <div css={customCSS}>
      <div css={factorsContainerCSS}>
        {scoreData.score.factors.map(f => (
          <FactorItem
            customCSS={factorCSS}
            key={f.key}
            title={f.title}
            titleHint={f.tooltip}
            value={f.display_value}
            valueHint={f.explanation}
            riskLevel={f.risk}
            impactLevel={f.impact_level}
          />
        ))}
      </div>

      {factorsEmptyState && (
        <Button variant="secondary" fullWidth onClick={handleActionClick}>
          {noFactorsButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default FactorsList;
