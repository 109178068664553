import type { SerializedStyles } from '@emotion/react';
import { css, keyframes } from '@emotion/react';
import theme from '../../../styles/theme';

const pulls = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const centeredCSS = css`
  margin-left: auto;
  margin-right: auto;
`;

export function loaderCSS(height = 20, width = 100, absoluteWidth?: number, centered = false): SerializedStyles {
  return css`
  content: '';
  background-color: ${theme.color.gray10};
  height: ${height}px;
  border-radius: 4px;
  width: ${absoluteWidth ? `${absoluteWidth}px` : `${width}%`};
  transition: width 1s;
  animation: ${pulls} 3s ease-in-out infinite;
  ${centered && centeredCSS};
`;
}

export function containerCSS(marginTop: number, marginBottom: number): SerializedStyles {
  return css`
    margin-top: ${marginTop}px;
    margin-bottom: ${marginBottom}px;
`;
}
