import type { FC } from 'react';
import type { RadioInputProps } from './RadioInput.type';
import { Controller } from 'react-hook-form';
import RadioGroup from '../../../../shared/components/RadioGroup/RadioGroup';
import { getValidationRules } from '../../../../utils/question-validations.util';

const RadioInput: FC<RadioInputProps> = ({
  name,
  options,
  isDisabled,
  validations,
  hasError,
}) => {
  return (
    <Controller
      name={name}
      rules={getValidationRules(validations)}
      render={({ field: { ref, value, onBlur, onChange } }) => (
        <RadioGroup
          name={name}
          value={value as boolean | string | undefined}
          options={options}
          disabled={isDisabled}
          componentRef={ref}
          hasError={hasError}
          onBlur={onBlur}
          onChange={(e) => {
            onChange({
              ...e,
              target: {
                value: e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value,
              },
            });
          }}
        />
      )}
    />
  );
};

export default RadioInput;
