import type { FC } from 'react';
import type { IntroStepsProps } from './IntroSteps.type';
import AnimatedDashRow from '../../../assets/svg/dp-start/anim-dashed-row.svg?react';
import { animatedDashedLoaderCSS, containerCSS } from './IntroSteps.style';
import { INTRO_STEPS_CONTENT } from './IntroSteps.utils';
import StepItem from './StepItem/StepItem';

const IntroSteps: FC<IntroStepsProps> = ({ customCSS }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      {INTRO_STEPS_CONTENT.map((content, i) => (
        <StepItem {...content} count={i + 1} key={content.description} />
      ))}
      <AnimatedDashRow css={animatedDashedLoaderCSS} />
    </div>
  );
};

export default IntroSteps;
