import type { SerializedStyles } from '@emotion/react';

import type { ReactNode } from 'react';
import type { Factor } from '../../../types/score.type.ts';

export enum ScoreGaugeType {
  Negative = 'negative',
  Positive = 'positive',
}

export interface ScoreCardProps {
  customCSS?: SerializedStyles;
  title: string;
  description: string;
  icon: ReactNode;
  scoreGaugeType?: ScoreGaugeType;
  score?: number | null;
  scoreMax?: number | null;
  tooltip?: string[];
  scoreButtonLabel?: string;
  factors?: Factor[];
  isLoading?: boolean;
  onViewAllFactorsClick?: () => void;
  onClick?: () => void;
  label?: ReactNode;
}
