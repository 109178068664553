import type { Ref } from 'react';
import type { RadioButtonProps } from './RadioButton.type';
import noop from '../../../utils/noop';
import {
  containerCSS,
  contentInnerCSS,
  inputCSS,
  labelContainerCSS,
  labelCSS,
  radioButtonDotCSS,
} from './RadioButton.style';
import { RADIO_BUTTON_CLASSNAMES } from './RadioButton.util';

function RadioButton({
  ref,
  customCSS,
  index,
  name,
  value,
  label,
  boldLabel,
  description,
  disabled,
  checked,
  imageUrl,
  children,
  hasError,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  ...props
}: RadioButtonProps & { ref?: Ref<HTMLInputElement | null> }) {
  return (
    <label css={[containerCSS(disabled), customCSS]} htmlFor={`${name}[${index}]`}>
      <input
        type="radio"
        css={inputCSS(hasError)}
        disabled={disabled}
        id={`${name}[${index}]`}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        {...props}
        ref={ref}
      />
      <div className={RADIO_BUTTON_CLASSNAMES.inner}>
        <div css={contentInnerCSS}>
          <div className={RADIO_BUTTON_CLASSNAMES.dot} css={radioButtonDotCSS()} />
          <div css={labelContainerCSS}>
            <div css={labelCSS(boldLabel ?? !!description)}>{label}</div>
          </div>
        </div>
      </div>
    </label>
  );
}

RadioButton.displayName = 'RadioButton';

export default RadioButton;
