/* eslint-disable react/prefer-destructuring-assignment */
import type { FC } from 'react';
import { components as selectComponents } from 'react-select';
import Chevron from '../../../assets/svg/dropdown-chevron.svg?react';

export const DropdownIndicator: FC<any> = (props: any) => (
  <selectComponents.DropdownIndicator {...props}>
    <Chevron />
  </selectComponents.DropdownIndicator>
);

export const MenuList: FC<any> = (props: any) => (
  <selectComponents.MenuList {...props}>
    <div
      role="listbox"
      aria-activedescendant={props.selectProps?.value?.value}
      tabIndex={0}
      // created for automation tests
      data-testid={`select-field-${props.selectProps.inputId.replace(/_/g, '-')}`}
    >
      {props.children}
    </div>
  </selectComponents.MenuList>
);

export const Option: FC<any> = props => (
  <div role="option" aria-selected={props.isSelected} id={props.value}>
    <selectComponents.Option {...props}>{props.label}</selectComponents.Option>
  </div>
);

export const Control: FC<any> = (props: any) => (
  <selectComponents.Control
    {...props}
    innerProps={{
      ...props.innerProps,
      'data-testid': `select-field-control-${props.selectProps.inputId.replace(/_/g, '-')}`,
    }}
  >
    {props.children}
  </selectComponents.Control>
);
