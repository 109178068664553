import type { FC } from 'react';
import type { DefaultRouteParams } from '../../../types/route-params.type';
import type { ScheduleCallPageProps } from './ScheduleCallPage.type';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import useMutateLeadCreation from '../../../api/lead/use-mutate-lead-creation.ts';

import useSettingsQuery from '../../../api/settings/use-settings-query.ts';
import useAnalytics from '../../../hooks/use-analytics.ts';
import useInitCalendly from '../../../hooks/use-init-calendly/use-init-calendly';
import { CALENDLY_STEP } from '../../../hooks/use-init-calendly/use-init-calendly.type';
import { CALENDLY_ID } from '../../../hooks/use-init-calendly/use-init-calendly.util';
import LoadingDots from '../../../shared/components/LoadingDots/LoadingDots';
import { AnalyticLocation, AnalyticPageKey } from '../../../types/analytics.type.ts';
import { calendlyWidgetCSS, loaderCSS, subtitleCSS, titleCSS } from './ScheduleCallPage.style';
import ScheduleCallTeam from './ScheduleCallTeam/ScheduleCallTeam';

const ScheduleCallPage: FC<ScheduleCallPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;

  const userQuery = useAccountQuery(gid);
  const { data: settings } = useSettingsQuery();
  const { mutate: createLead } = useMutateLeadCreation(gid);
  const { track } = useAnalytics();

  const prefillData = {
    firstName: userQuery.data?.account.first_name,
    lastName: userQuery.data?.account.last_name,
    email: userQuery.data?.account.email,
    phone: userQuery.data?.account.phone || '',
  };
  const [calendlyUrl, setCalendlyUrl] = useState<string | null>(null);
  const { step, calendlyInit, isLoaded } = useInitCalendly({
    gid: userQuery.data?.account.engagement_gid,
    url: calendlyUrl,
    prefillData,
    onEventScheduled: createLead,
  });

  const isAllowInit = !userQuery.isLoading;
  const eventIsScheduled = step === CALENDLY_STEP.eventScheduled;

  useEffect(() => {
    if (settings?.calendly) {
      const url = settings.calendly.url;
      setCalendlyUrl(url);
    }
  }, [settings?.calendly]);

  useEffect(() => {
    if (calendlyUrl && isAllowInit) {
      calendlyInit();
    }
  }, [calendlyUrl, calendlyInit, isAllowInit]);

  const onPhoneClick = () => {
    track('Phone Conversation Initiated', {
      page: AnalyticPageKey.ScheduleCall,
      location: AnalyticLocation.MaticTeamBlock,
    });
  };

  return (
    <div css={customCSS}>
      <div css={titleCSS}>
        {eventIsScheduled ? 'You’re all set!' : 'Schedule a Call'}
      </div>
      <div css={subtitleCSS}>
        {eventIsScheduled
          ? 'A confirmation email with your appointment details has been sent.'
          : 'Schedule a time that works for you.'}
      </div>
      {!isLoaded && <LoadingDots customCSS={loaderCSS} />}
      <div id={CALENDLY_ID} data-testid="calendly-widget" css={[calendlyWidgetCSS(step)]} />
      <ScheduleCallTeam
        agentEmail={settings?.email || ''}
        agentPhone={settings?.phone_number || ''}
        onPhoneNumberClick={onPhoneClick}
      />
    </div>
  );
};

export default ScheduleCallPage;
