import type { HTTPResponse } from '../../types/api.type';

import type { Proposal } from '../../types/proposal.type';
import { skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';

function usePersonProposalsQuery(
  {
    personGid,
    engagementGid,
  }:
  {
    personGid?: string;
    engagementGid?: string;
  },
) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.proposal, personGid, engagementGid],
    queryFn: personGid && engagementGid
      ? async () => {
        const url = API_PATH.proposals.replace(':person_gid', personGid).replace(':engagement_gid', engagementGid);
        return await api.get<HTTPResponse<{ proposals: Proposal[] }>>(url);
      }
      : skipToken,
    select: ({ data }) => data.proposals,
  });
}

export default usePersonProposalsQuery;
