import type { FC } from 'react';
import type { OnChangeValue } from 'react-select';
import type { SelectOption, SelectProps } from './Select.type';
import { useRef } from 'react';
import ReactSelect from 'react-select';
import noop from '../../utils/noop';
import { DEFAULT_SELECT_OPTIONS } from './Select.constants';
import { selectCSS } from './Select.style';
import { Control, DropdownIndicator, MenuList, Option } from './SelectParts.utils';

const SELECT_DEFAULT_PLACEHOLDER = 'Select...';
const DEFAULT_COMPONENTS = {};

const Select: FC<SelectProps> = ({
  customCSS,
  inputId,
  ariaLabel,
  options,
  onValidEntry = noop,
  placeholder = SELECT_DEFAULT_PLACEHOLDER,
  components = DEFAULT_COMPONENTS,
  menuShouldBlockScroll = false,
  componentRef,
  hasError,
  onBlur,
  value,
  styles,
  dense,
  isPrefilled,
  isSearchable = false,
  isDisabled = false,
  blurInputOnSelect,
  onValidatePreviousInputs = noop,
}) => {
  if (!options) {
    throw new Error('Select Component: options must be provided.');
  }

  const selectRef = useRef<any>(null);
  if (componentRef && selectRef.current) {
    componentRef(selectRef.current.inputRef);
  }

  const selectValue = options.find(option => option.value === value) ?? null;
  const onChange = (option: OnChangeValue<unknown, false>): void => {
    onValidEntry((option as OnChangeValue<SelectOption, false>)?.value ?? null);
    onValidatePreviousInputs();
  };

  return (
    <ReactSelect
      css={[selectCSS(hasError, isDisabled, dense, isPrefilled), customCSS]}
      {...DEFAULT_SELECT_OPTIONS}
      inputId={inputId}
      aria-label={ariaLabel}
      options={options}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      value={selectValue}
      onChange={onChange}
      onBlur={onBlur}
      menuShouldBlockScroll={menuShouldBlockScroll}
      blurInputOnSelect={blurInputOnSelect}
      styles={styles}
      components={{
        MenuList,
        Option,
        DropdownIndicator,
        Control,
        ...components,
      }}
      ref={selectRef}
    />
  );
};

export default Select;
