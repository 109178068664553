import type { SerializedStyles } from '@emotion/react';
import type { AnchorType } from './Anchor.type';

import { css } from '@emotion/react';
import THEME from '../../../styles/theme.ts';

function getLinkColor(type: AnchorType): SerializedStyles {
  switch (type) {
    case 'underline-active':
      return css`
        color: inherit;
        border-bottom-color: ${THEME.color.azure50};
      `;
    case 'single-color':
      return css`
        color: inherit;
        border-bottom-color: inherit;
      `;
    default:
      return css`
        color: ${THEME.color.azure50};
        border-bottom-color: ${THEME.color.azure50};
      `;
  }
}

function getLinkHoverColor(type: AnchorType, inverted: boolean): SerializedStyles {
  switch (type) {
    case 'underline-active':
      return css`
        color: ${THEME.color.azure68};
        border-bottom-color: ${THEME.color.azure68};
      `;
    case 'single-color':
    default:
      return css`
        color: ${inverted ? THEME.color.azure95 : THEME.color.azure68};
        border-bottom-color: ${inverted ? THEME.color.azure95 : THEME.color.azure68};
      `;
  }
}

function getLinkActiveColor(type: AnchorType, inverted: boolean): SerializedStyles {
  switch (type) {
    case 'underline-active':
      return css`
        color: ${THEME.color.azure50};
        border-bottom-color: ${THEME.color.azure50};
      `;
    case 'single-color':
    default:
      return css`
        color: ${inverted ? THEME.color.azure68 : THEME.color.azure50};
        border-bottom-color: ${inverted ? THEME.color.azure68 : THEME.color.azure50};
      `;
  }
}

function getLinkVisitedColor(type: AnchorType): SerializedStyles {
  switch (type) {
    case 'underline-active':
      return css`
        border-bottom-color: ${THEME.color.violet};
      `;
    case 'single-color':
      return css`
        color: inherit;
        border-bottom-color: inherit;
      `;
    default:
      return css`
        color: ${THEME.color.violet};
        border-bottom-color: ${THEME.color.violet};
      `;
  }
}

export function linkCSS(type: AnchorType, inverted = false): SerializedStyles {
  return css`
  border-bottom: 2px solid ${THEME.color.azure50};
  ${getLinkColor(type)}
  cursor: pointer;
  text-decoration: none;
  transition:
    color 0.2s ease,
    border-bottom-color 0.2s ease;
  &:visited {
    ${getLinkVisitedColor(type)}
  }
  &:focus,
  &:active {
    ${getLinkActiveColor(type, inverted)}
  }
  &:hover {
    ${getLinkHoverColor(type, inverted)}
  }
`;
}
