import type { FC } from 'react';

import type { LoaderDotProps, LoaderProps } from './Loader.type.ts';
import { dotCSS, loaderCSS, loaderWrapperCSS } from './Loader.style.ts';

import { LoaderSizeEnum } from './Loader.type.ts';

const LoaderDot: FC<LoaderDotProps> = ({ inverted, colored, size = LoaderSizeEnum.Small }) => (
  <div css={dotCSS(inverted, colored, size)} />
);

const Loader: FC<LoaderProps> = ({
  colored = false,
  inverted = false,
  fullPage = false,
  size = LoaderSizeEnum.Small,
  customCSS,
}) => (
  <div css={[loaderWrapperCSS(fullPage), customCSS]} data-testid="loader">
    <div css={loaderCSS}>
      <LoaderDot inverted={inverted} colored={colored} size={size} />
      <LoaderDot inverted={inverted} colored={colored} size={size} />
      <LoaderDot inverted={inverted} colored={colored} size={size} />
    </div>
  </div>
);

export default Loader;
