import type { FC } from 'react';
import type { AutoQuoteAdProps } from './AutoQuoteAd.type';

import { useState } from 'react';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import { PROPOSAL_SEGMENTS } from '../../../constants/analytics';
import SIRV_IMAGES from '../../../constants/sirv-images';
import Button from '../../../shared/components/Button/Button';
import THEME from '../../../styles/theme';
import analyticsUtil from '../../../utils/analytics.util.ts';
import fullStoryUtil from '../../../utils/full-story.util.ts';
import AgentInfoModal from '../AgenContactInfoModal/AgentContactInfoModal';
import { bodyCSS, buttonCSS, containerCSS, headerCSS, textBodyCSS } from './AutoQuoteAd.style';

const AutoQuoteAd: FC<AutoQuoteAdProps> = ({ agentContactInfo, isProposalExpired, proposalGid }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleActionClick = () => {
    analyticsUtil.track(PROPOSAL_SEGMENTS.events.agentContactViewed, {
      location: PROPOSAL_SEGMENTS.locations.adCard,
      proposal_gid: proposalGid,
    });
    fullStoryUtil.track(PROPOSAL_SEGMENTS.events.agentContactViewed, {
      location: PROPOSAL_SEGMENTS.locations.adCard,
      proposal_gid: proposalGid,
    });
    setModalOpen(true);
  };

  return (
    <ProposalCard backgroundColor={THEME.color.positiveGreen94}>
      <div css={containerCSS}>
        <div css={bodyCSS}>
          <div css={textBodyCSS}>
            <p css={headerCSS}>Want to save even more?</p>
            <p>
              Did you know you could save up to 20% when Matic shops your auto insurance, too? We work hard to find
              discounts – even checking to see if using two carriers gets better rates than bundling. Chat with a
              licensed advisor to get a free auto quote.
            </p>
          </div>
          <img src={SIRV_IMAGES.car} width="265" height="135" alt="Blue car" />
        </div>
        <Button customCSS={buttonCSS} onClick={handleActionClick}>
          Contact Your Advisor
        </Button>
      </div>
      {isModalOpen && (
        <AgentInfoModal
          agentContactInfo={agentContactInfo}
          setModalOpen={setModalOpen}
          isProposalExpired={isProposalExpired}
        />
      )}
    </ProposalCard>
  );
};

export default AutoQuoteAd;
