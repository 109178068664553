import type { SerializedStyles } from '@emotion/react';
import type { GridWidth, GridWidthValue } from '../../types/form.type.ts';

import { css } from '@emotion/react';

import { mq } from '../../styles/media-queries.ts';
import THEME from '../../styles/theme.ts';

const disabledCSS = css`
  opacity: 0.5;
  pointer-events: none;
`;

function gridWidthCSS(gridWidth: GridWidthValue): SerializedStyles {
  return css`
    flex-basis: ${(gridWidth / 12) * 100}%;
    padding-right: ${THEME.size.inputHorizontalSpacing}px;
  `;
}

export function generateGridWidthCSS(gridWidth: GridWidth): SerializedStyles | SerializedStyles[] {
  if (Array.isArray(gridWidth)) {
    return gridWidth.map((value, index) => index === 0
      ? css`${gridWidthCSS(value)}`
      : css`${mq[index - 1]} { ${gridWidthCSS(value)} }`);
  }
  else {
    return css`
      padding-right: ${THEME.size.inputHorizontalSpacing}px;
      ${mq[0]} { ${gridWidthCSS(gridWidth)} }
    `;
  }
}

export function containerCSS(isDisabled?: boolean, gridWidth?: GridWidth | null): SerializedStyles {
  return css`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  ${isDisabled && disabledCSS};
  ${gridWidth && generateGridWidthCSS(gridWidth)};
`;
}

export const labelCSS = css`
  margin-bottom: 4px;

  span {
    font-weight: 700;
    margin-left: 4px;
  }
`;

export const errorCSS = css`
  margin-top: 4px;

  a {
    text-decoration: none;
  }

  a:visited {
    color: ${THEME.color.azure62};
  }
`;

export const innerWrapperCSS = css`
  border: 1px solid ${THEME.color.black};
  border-radius: ${THEME.size.borderRadius}px;
  padding: 16px 20px;
`;

export const labelWithIconCSS = css`
  font-weight: 700;
  margin-left: 12px;
`;

export const descriptionWithIconCSS = css`
  margin-bottom: 4px;
  line-height: 20px;
`;

export const iconLabelWrapperCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
