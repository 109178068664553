/**
 * https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory
 */
export const FS_SENSITIVE_DATA_CLASS = {
  exclude: 'fs-exclude',
  mask: 'fs-mask',
  unmask: 'fs-unmask',
};

export const FS_ELEMENTS = {
  selectQuoteButton: 'Select Quote Button',
};
