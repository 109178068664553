import type { ForwardedRef } from 'react';

import type { WrapperProps } from './Wrapper.type';

function Wrapper({ ref, tag, children, ...props }: WrapperProps & { ref?: React.RefObject<HTMLElement | null> }) {
  switch (tag) {
    case 'a':
      return (
        <a {...props} ref={ref as ForwardedRef<HTMLAnchorElement>}>
          {children}
        </a>
      );
    case 'button':
      return (
        <button type="button" {...props} ref={ref as ForwardedRef<HTMLButtonElement>}>
          {children}
        </button>
      );
    case 'header':
      return (
        <header {...props} ref={ref}>
          {children}
        </header>
      );
    case 'span':
      return (
        <span {...props} ref={ref as ForwardedRef<HTMLSpanElement>}>
          {children}
        </span>
      );
    case 'label':
      return (
        <label {...props} ref={ref as ForwardedRef<HTMLLabelElement>}>
          {children}
        </label>
      );
    default:
      return (
        <div {...props} ref={ref as ForwardedRef<HTMLDivElement>}>
          {children}
        </div>
      );
  }
}

Wrapper.displayName = 'Wrapper';
export default Wrapper;
