import { fake_premium_history_data } from '../../../api/history/mocks/history.mock';

export const CHART_DATA_SET = {
  status: 'ready',
  ...fake_premium_history_data,
};

export const EST_TOOLTIP = 'Your estimated premium is based on your current premium and the average percent '
  + 'increase of premiums in your state renewing this year and next year. Rates and discounts vary, are determined '
  + 'by many factors, and are subject to change. Our prediction model has a mean absolute percentage error of '
  + '9%, meaning that on average, our predictions may be off by about 9% compared to actual renewal premium.';
