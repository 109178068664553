import type { FC } from 'react';
import type { ToolsToOptimizeProps } from './ToolsToOptimizeCard.type';
import { cardDescriptionCSS, cardTitleCSS, toolsCardCSS } from './ToolsToOptimizeCard.style';

const ToolsToOptimize: FC<ToolsToOptimizeProps> = ({ customCSS, title, description, icon }) => {
  return (
    <div css={[toolsCardCSS, customCSS]}>
      {icon}
      <h3 css={cardTitleCSS}>{title}</h3>
      <p css={cardDescriptionCSS}>{description}</p>
    </div>
  );
};

export default ToolsToOptimize;
