import type { FC } from 'react';
import { Global } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router';
import useSettingsQuery from './api/settings/use-settings-query.ts';
import AuthGuard from './components/AuthGuard/AuthGuard.tsx';
import AuthRoot from './components/AuthRoot/AuthRoot.tsx';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SessionRoot from './components/SessionRoot/SessionRoot.tsx';
import ROUTES, { RATE_REVIEW } from './constants/routes';
import { ActionCableContext, setupCableConsumer } from './context/action-cable-context.ts';
import useInitAnalytics from './hooks/use-init-analytics.ts';
import AccountLayout from './pages/account/AccountLayout.tsx';
import Authenticate from './pages/authenticate/Authenticate.tsx';
import CarrierOptionsLayout from './pages/carrier-options/CarrierOptionsLayout.tsx';
import CheckEmailLayout from './pages/check-email/CheckEmailLayout.tsx';
import CreditScoreDetailsLayout from './pages/credit-score-details/CreditScoreDetailsLayout.tsx';
import DisclaimersLayout from './pages/disclaimers/DisclaimersLayout.tsx';
import DpIntroLayout from './pages/dp-intro/DpIntroLayout.tsx';
import ErrorLayout from './pages/error/ErrorLayout.tsx';
import ErrorFallbackGlobal from './pages/error/fallbacks/ErrorFallbackGlobal/ErrorFallbackGlobal.tsx';
import ErrorFallbackSession from './pages/error/fallbacks/ErrorFallbackSession/ErrorFallbackSession.tsx';
import InsightsLayout from './pages/insights/InsightsLayout.tsx';
import LinkExpiredLayout from './pages/link-expired/LinkExpiredLayout.tsx';
import PoliciesLayout from './pages/policies/PoliciesLayout.tsx';
import ProposalLayout from './pages/proposal/ProposalLayout.tsx';
import RateReviewLayout from './pages/rate-review/RateReviewLayout.tsx';
import ReplacementCostLayout from './pages/replacement-cost/ReplacementCostLayout.tsx';
import ScheduleCallLayout from './pages/schedule-call/ScheduleCallLayout.tsx';
import SignInLayout from './pages/sign-in/SignInLayout.tsx';
import SignUpLayout from './pages/sign-up/SignUpLayout.tsx';
import SnapshotDetailsLayout from './pages/snapshot-details/SnapshotDetailsLayout.tsx';
import VerifyLayout from './pages/verify/VerifyLayout.tsx';
import FullPageLoader from './shared/components/FullPageLoader/FullPageLoader.tsx';
import normalizeCSS, { globalCSS } from './styles/global.style.ts';

const App: FC = () => {
  const { data: settingsData } = useSettingsQuery(false);
  const { reset } = useQueryErrorResetBoundary();
  useInitAnalytics({
    segment: settingsData?.segment,
    sentry: settingsData?.sentry,
    fullStory: settingsData?.full_story,
  });

  if (!settingsData) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Global styles={normalizeCSS} />
      <Sentry.ErrorBoundary
        onReset={reset}
        fallback={({ resetError }) => <ErrorFallbackGlobal resetError={resetError} />}
      >
        <ActionCableContext value={setupCableConsumer()}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route
                path={ROUTES.authenticate}
                element={(
                  <Sentry.ErrorBoundary fallback={<Navigate to={ROUTES.problem} />}>
                    <Authenticate />
                  </Sentry.ErrorBoundary>
                )}
              />

              <Route
                path={ROUTES.sessionBase}
                element={(
                  <Sentry.ErrorBoundary
                    fallback={({ error }) => <ErrorFallbackSession error={error} />}
                  >
                    <AuthGuard>
                      <SessionRoot />
                    </AuthGuard>
                  </Sentry.ErrorBoundary>
                )}
              >
                <Route index element={<InsightsLayout />} />

                <Route path={ROUTES.verify} element={<VerifyLayout />} />
                <Route path={ROUTES.insights} element={<InsightsLayout />} />
                <Route path={ROUTES.rateReview} element={<RateReviewLayout />} />
                <Route path={ROUTES.replacementCost} element={<ReplacementCostLayout />} />
                <Route path={ROUTES.carrierOptions} element={<CarrierOptionsLayout />} />
                <Route path={ROUTES.digitalProfileIntro} element={<DpIntroLayout />} />
                <Route path={ROUTES.policies} element={<PoliciesLayout />} />
                <Route path={ROUTES.snapshotDetails} element={<SnapshotDetailsLayout />} />
                <Route path={ROUTES.scheduleCall} element={<ScheduleCallLayout />} />
                <Route path={ROUTES.account} element={<AccountLayout />} />
                <Route path={ROUTES.creditScoreDetails} element={<CreditScoreDetailsLayout />} />
                <Route path={ROUTES.disclaimers} element={<DisclaimersLayout />} />
              </Route>

              <Route path={ROUTES.auth} element={<AuthRoot />}>
                <Route index element={<SignInLayout />} />

                <Route
                  path={ROUTES.linkExpired}
                  element={(
                    <Sentry.ErrorBoundary fallback={<Navigate to={ROUTES.problem} />}>
                      <LinkExpiredLayout />
                    </Sentry.ErrorBoundary>
                  )}
                />
                <Route
                  path={ROUTES.checkEmail}
                  element={(
                    <Sentry.ErrorBoundary fallback={<Navigate to={ROUTES.problem} />}>
                      <CheckEmailLayout />
                    </Sentry.ErrorBoundary>
                  )}
                />
                <Route
                  path={ROUTES.signIn}
                  element={<SignInLayout />}
                />
                <Route
                  path={ROUTES.signUp}
                  element={<SignUpLayout />}
                />
              </Route>

              <Route
                path={ROUTES.problem}
                element={(
                  <>
                    <ErrorLayout />
                    <Global styles={globalCSS} />
                  </>
                )}
              />
              <Route
                path={ROUTES.invalid}
                element={(
                  <>
                    <ErrorLayout hideActionButton />
                    <Global styles={globalCSS} />
                  </>
                )}
              />

              <Route
                path={ROUTES.root}
                element={<Navigate to={ROUTES.signIn} />}
              />

              <Route
                path={ROUTES.proposal}
                element={(
                  <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'proposal');
                    }}
                    fallback={(
                      <>
                        <ErrorLayout hideActionButton />
                        <Global styles={globalCSS} />
                      </>
                    )}
                  >
                    <ProposalLayout />
                    <Global styles={globalCSS} />
                  </Sentry.ErrorBoundary>
                )}
              />

              <Route
                path={RATE_REVIEW}
                element={(
                  <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'rate_review_v1');
                    }}
                    fallback={<Navigate to={ROUTES.problem} />}
                  >
                    <Navigate to={ROUTES.signUp} />
                  </Sentry.ErrorBoundary>
                )}
              />
            </Routes>
          </BrowserRouter>
        </ActionCableContext>
      </Sentry.ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
