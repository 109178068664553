import type { HTTPResponse } from '../../types/api.type';
import type { SessionResponse } from '../../types/session.type.ts';
import { skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { checkResponseType } from '../../utils/error.util.ts';
import api from '../api';
import { SessionResponseSchema } from './session.schema.ts';

export function getSession(gid: string) {
  return api
    .get<HTTPResponse<SessionResponse>>(API_PATH.session.replace(':session_gid', gid));
}

function useSessionQuery(gid?: string | null) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.session, gid],
    queryFn: gid
      ? async () => await getSession(gid).then(res =>
        checkResponseType<SessionResponse>(res.data, SessionResponseSchema),
      )
      : skipToken,
  });
}

export default useSessionQuery;
