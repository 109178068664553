import type { FC } from 'react';

import type { AgentContactInfoModalProps } from './AgentContactInfoModal.type';
import EmailIcon from '../../../assets/svg/email.svg?react';
import PhoneIcon from '../../../assets/svg/phone.svg?react';
import WarningIcon from '../../../assets/svg/warning.svg?react';
import Modal from '../../../shared/components/Modal/Modal';
import { ModalSize } from '../../../shared/components/Modal/Modal.type';
import {
  agentContactCSS,
  agentContactItemCSS,
  agentInfoCSS,
  expiredWarningBlockCSS,
  expiredWarningTextCSS,
} from './AgentInfoModal.style';

const AgentContactInfoModal: FC<AgentContactInfoModalProps> = ({
  agentContactInfo,
  setModalOpen,
  isProposalExpired,
}) => {
  return (
    <Modal isOpen title="Your Agent's Contact Information" size={ModalSize.Medium} onClose={() => setModalOpen(false)}>
      <div css={agentInfoCSS}>
        {isProposalExpired && (
          <div css={expiredWarningBlockCSS}>
            <WarningIcon />
            <p css={expiredWarningTextCSS}>
              The proposal pricing may not be current. Insurance rates change frequently, so quoted prices might differ
              from current offerings. For accuracy, review updated rates and coverage with your agent to make decisions
              based on current information.
            </p>
          </div>
        )}
        <p>
          Feel free to reach out to your dedicated agent. Your agent is here to guide you through the process and ensure
          you get the best coverage tailored to your needs.
        </p>

        <div css={agentContactCSS}>
          <div css={agentContactItemCSS}>
            <PhoneIcon />
            {agentContactInfo?.phone_number}
          </div>
          <div css={agentContactItemCSS}>
            <EmailIcon />
            {agentContactInfo?.email}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgentContactInfoModal;
