import type { FC } from 'react';
import type { TooltipFrameProps } from './TooltipFrame.type.ts';

import { descriptionCSS, fakeTooltipCSS, titleCSS } from './TooltipFrame.style.ts';

const TooltipFrame: FC<TooltipFrameProps> = ({ customCSS, title, description, children }) => {
  return (
    <div css={[fakeTooltipCSS, customCSS]}>
      <h3 css={titleCSS}>{title}</h3>
      <p css={descriptionCSS}>{description}</p>

      {children}
    </div>
  );
};

export default TooltipFrame;
