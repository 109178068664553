import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import useAccountQuery from '../api/account/use-account-query.ts';
import ROUTES from '../constants/routes.ts';
import { getTargetPath } from '../utils/routes.util.ts';

function useAccountCheck(gid?: string | null, targetPage?: string | null) {
  const { data } = useAccountQuery(gid, false, false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.account?.verified && gid) {
      navigate(getTargetPath(gid, targetPage));
    }
    else if (data?.account && gid) {
      navigate(ROUTES.verify.replace(':gid', gid));
    }
  }, [data, gid, navigate, targetPage]);
}

export default useAccountCheck;
