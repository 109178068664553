import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface HistoryStore {
  history: string[];
  addToHistory: (path: string) => void;
  goBack: (fallBackUrl: string) => string;
}

export const useHistoryStore = create<HistoryStore>()(
  persist(
    (set, get) => ({
      history: [],
      addToHistory: (path) => {
        const { history } = get();
        if (history[history.length - 1] !== path) {
          set({ history: [...history, path] });
        }
      },
      goBack: (fallBackUrl) => {
        const { history } = get();

        if (history.length > 1) {
          const newHistory = history.slice(0, -1);
          set({ history: newHistory });
          return newHistory[newHistory.length - 1];
        }

        return fallBackUrl;
      },
    }),
    {
      name: 'history-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
