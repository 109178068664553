import type { FC } from 'react';
import type { RadioGroupProps } from './RadioGroup.type';
import noop from '../../utils/noop';
import RadioButton from './RadioButton/RadioButton';
import { containerCSS } from './RadioGroup.style';

const OPTIONS_LIMIT = 4;

const RadioGroup: FC<RadioGroupProps> = ({
  customCSS,
  name,
  value,
  options,
  disabled,
  componentRef,
  hasError,
  onBlur = noop,
  onChange = noop,
}) => {
  if (!options) {
    throw new Error('Radio group: options must be provided.');
  }

  return (
    <div css={[containerCSS, customCSS]}>
      {options
        .filter((_, i) => i < OPTIONS_LIMIT)
        .map((o, i) => (
          <RadioButton
            index={i}
            key={o.value}
            name={name}
            label={o.label}
            value={o.value}
            description={o.description}
            imageUrl={o.imageUrl}
            ref={i === 0 && componentRef ? componentRef : undefined}
            disabled={disabled}
            checked={value === o.value}
            hasError={hasError}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(event);
              event.target.blur();
            }}
          />
        ))}
    </div>
  );
};

export default RadioGroup;
