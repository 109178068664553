import type { FC } from 'react';

import type { AnchorProps } from './Anchor.type';
import { visuallyHiddenCSS } from '../../shared.style';
import { linkCSS } from './Anchor.style';

const Anchor: FC<AnchorProps> = ({
  href,
  target = '_self',
  anchorType = 'default',
  inverted = false,
  customCSS,
  children,
  ...props
}) => {
  const openNewTab = target === '_blank';

  return (
    <a
      css={[linkCSS(anchorType, inverted), customCSS]}
      {...props}
      href={href}
      target={target}
      rel={openNewTab ? 'noopener noreferrer' : ''}
    >
      {children}
      {openNewTab && <span css={visuallyHiddenCSS}>(opens a new window)</span>}
    </a>
  );
};

export default Anchor;
