/** TODO: Move to v2 api */
export const SETTINGS = `/api/frontend/v1/settings` as const;

/** TODO: Remove v1 constants */

const BASE = '/api/frontend/v2' as const;

const API_PATH = {
  sessions: `${BASE}/sessions`,
  session: `${BASE}/sessions/:session_gid`,
  sessionTrack: `${BASE}/sessions/track`,
  sessionCurrent: `${BASE}/sessions/current`,
  sessionRefresh: `${BASE}/sessions/refresh`,
  magicLink: `${BASE}/sessions/magic_link`,
  account: `${BASE}/accounts/:gid`,
  verifyAccount: `${BASE}/accounts/:gid/verify`,
  scores: `${BASE}/accounts/:gid/scores/:type`,
  calculate_score: `${BASE}/accounts/:gid/calculate_score/:type`,
  quotes: `${BASE}/accounts/:gid/quotes`,
  suggestedQuote: `${BASE}/accounts/:gid/quotes/suggested`,
  customerChoice: `${BASE}/accounts/:gid/quotes/:quote_gid/customer_choice`,
  availableCarriers: `${BASE}/accounts/:gid/carriers`,
  digitalProfile: () => `${API_PATH.account}/profile` as const,
  recentPolicy: () => `${API_PATH.account}/recent_policy` as const,
  leads: `${BASE}/accounts/:gid/leads`,
  disclosures: `${BASE}/disclosures`,
  proposals: `${BASE}/proposals?person_gid=:person_gid&engagement_gid=:engagement_gid`,
  proposal: `${BASE}/proposals/:gid`,
  trackProposalOpened: `${BASE}/proposals/:gid/track_opened`,
  proposalUserPresence: `${BASE}/proposals/:gid/user_presence`,
  person: `${BASE}/people/:gid`,
  personAssets: `${BASE}/people/:gid/assets`,
  relatedPeople: `${BASE}/people/:gid/related_people`,
  agentContactInfo: `${BASE}/agents/:gid/contact_info`,
  soldCustomersCount: `${BASE}/agents/:gid/sold_customers_count`,
  personLoans: `${BASE}/people/:gid/loans`,
  maticContent: `https://matic.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,
  wp:term&_fields=title,link,excerpt,_links,_embedded&per_page=30&categories=:categoryId`,
  savings: `${BASE}/people/:person_gid/policies/savings`,
  publicRecentPolicy: `${BASE}/people/:person_gid/policies/recent`,
  history: () => `${API_PATH.account}/history` as const,
  premiumHistory: () => `${API_PATH.history()}/premium` as const,
  replacementCostHistory: () => `${API_PATH.history()}/replacement_cost` as const,
  quotesOverTimeHistory: () => `${API_PATH.history()}/quotes_over_time` as const,
  fullStorySessions: `${BASE}/proposals/:proposal_gid/full_story_sessions`,
  createPersonPolicy: `${BASE}/people/:person_gid/policies`,
  updatePersonPolicy: `${BASE}/people/:person_gid/policies/:policy_gid`,
} as const;

export default API_PATH;
