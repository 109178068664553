import { z } from 'zod';
import { PaymentMethod } from '../../types/person-loan.type';
import { PolicyType } from '../../types/quote.type';
import { CarrierSchema } from '../available-carriers/available-carriers.schema';
import { QuoteCoverageSchema } from '../quotes/quote.schema';

export const PolicySchema = z.object({
  asset_gids: z.array(z.string()).nullable(),
  carrier: CarrierSchema.nullable(),
  created_at: z.string().nullable(),
  // TODO change back to datetime
  effective_date: z.string().nullable(),
  expiration_date: z.string().nullable(),
  gid: z.string().nullable(),
  lender_placed_insurance_issue_date: z.string().datetime({ offset: true }).nullable(),
  agency_name: z.string().nullable(),
  payment_method: z.nativeEnum(PaymentMethod).nullable(),
  policy_number: z.string().nullable(),
  policy_type: z.nativeEnum(PolicyType).nullable(),
  premium: z.string().nullable(),
  status: z.string().nullable(),
  coverages: z.array(QuoteCoverageSchema).nullable(),
});

export const RecentPolicyResponseSchema = z.object({
  person_policy: PolicySchema,
});
