import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import THEME from '../../../styles/theme';

export const toolsCardCSS = css`
  border-radius: 8px;
  border: 1px solid ${THEME.color.gray10};
  padding: 20px;
  box-sizing: border-box;
  text-align: center;

  ${mq[2]} {
    padding: 40px;
  }
`;

export const cardTitleCSS = css`
  margin: 20px 0 12px;
  font-size: 20px;
  line-height: 28px;

  ${mq[1]} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const cardDescriptionCSS = css`
  ${mq[1]} {
    font-size: 20px;
    line-height: 28px;
  }
`;
