import type { HTTPResponse } from '../../types/api.type';

import type { PersonLoan } from '../../types/person-loan.type';
import { skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';

function usePersonLoansQuery({ personGid }: { personGid?: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.personLoans, personGid],
    queryFn: personGid
      ? async () =>
        await api.get<HTTPResponse<{ loans: PersonLoan[] }>>(API_PATH.personLoans.replace(':gid', personGid))
      : skipToken,
    select: ({ data }) => data.loans,
  });
}

export default usePersonLoansQuery;
