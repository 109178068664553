import type { HTTPResponse } from '../../types/api.type';
import type { SuggestedQuoteResponse } from '../../types/quote.type.ts';
import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import api from '../api';
import { SuggestedQuoteResponseSchema } from '../quotes/quote.schema.ts';

function useSuggestedQuoteQuery(gid: string, enabled?: boolean) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.suggestedQuote, gid],
    enabled,
    queryFn: async () =>
      await api.get<HTTPResponse<SuggestedQuoteResponse>>(API_PATH.suggestedQuote.replace(':gid', gid)).then((res) => {
        if (res.session.refreshed_at) {
          updateLastUserInteractionTime(res.session.refreshed_at * 1000);
        }

        return checkResponseType<SuggestedQuoteResponse>(res.data, SuggestedQuoteResponseSchema);
      }),
  });
}

export default useSuggestedQuoteQuery;
