import type { AnalyticPageKey } from '../types/analytics.type';
import type { DpCta } from '../types/digital-profile.type';
import type { DefaultRouteParams } from '../types/route-params.type';
import { createSearchParams, useNavigate, useParams } from 'react-router';
import ROUTES from '../constants/routes';

export default function useDpNavigate() {
  const { gid } = useParams() as DefaultRouteParams;
  const navigate = useNavigate();

  const navigateToProfileIntro = (cta: DpCta, targetPage: AnalyticPageKey) => {
    const path = ROUTES.digitalProfileIntro.replace(':gid', gid);
    const searchParams = createSearchParams({ cta, targetPage }).toString();

    navigate({ pathname: path, search: searchParams });
  };

  return navigateToProfileIntro;
}
