import type { HistoryData, ReplacementCostHistoryItem } from '../../../types/history.type';

const currentYear = new Date().getFullYear();
const numberChangeByPercent = (number: number, percent: number): number => number + number * percent / 100;

const replacement_premium_initial_data = [
  {
    year: currentYear - 3,
    replacement_cost: 280000,
  },
  {
    year: currentYear - 2,
    replacement_cost: 300000,
  },
  {
    year: currentYear - 1,
    replacement_cost: 300000,
  },
  {
    year: currentYear,
    replacement_cost: 300000,
  },
  {
    year: currentYear + 1,
    replacement_cost: 300000,
  },
];

export const fake_replacement_premium_data: { history: HistoryData<ReplacementCostHistoryItem> } = {
  history: {
    ...replacement_premium_initial_data.reduce((acc, cur) => ({ ...acc, [cur.year]: {
      replacement_cost: cur.replacement_cost,
      replacement_cost_min: numberChangeByPercent(cur.replacement_cost, -10),
      replacement_cost_max: numberChangeByPercent(cur.replacement_cost, 10),
    } }), {}),
  },
};

export const fake_premium_history_data = {
  history: {
    [currentYear - 3]: {
      policy_premium: 1630,
      quote_premium: 1707,
    },
    [currentYear - 2]: {
      policy_premium: 1710,
      quote_premium: 1715,
    },
    [currentYear - 1]: {
      policy_premium: 1890,
      quote_premium: 1842,
    },
    [currentYear]: {
      policy_premium: 2010,
      quote_premium: 1850,
    },
    [currentYear + 1]: {
      policy_premium: 1990,
      quote_premium: 1790,
    },
  },
};

export const fake_quotes_over_time_data = {
  history: {
    [currentYear - 3]: 6,
    [currentYear - 2]: 5,
    [currentYear - 1]: 3,
    [currentYear]: 4,
  },
};
