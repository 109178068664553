import type { HTTPResponse } from '../../types/api.type.ts';
import type { PolicyInfo, ScoreResponse, ScoreType } from '../../types/score.type.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import api from '../api.ts';

function useMutatePolicyInfo(gid: string, type: ScoreType) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: PolicyInfo) =>
      await api
        .post<HTTPResponse<ScoreResponse>>(API_PATH.calculate_score.replace(':gid', gid).replace(':type', type), {
          body: { data: { score_factors: data } },
        })
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          await queryClient.setQueryData([QUERY_CACHE_KEYS.score, gid, type], { ...res.data, policy_info: data });

          return res.data;
        })
        .catch((error) => {
          throw error;
        }),
  });
}

export default useMutatePolicyInfo;
