import type { FC } from 'react';

import type { ContainerProps } from './Container.type';
import { containerCSS } from './Container.style';

const Container: FC<ContainerProps> = ({
  isAriaLivePolite,
  customCSS,
  fullscreen = false,
  small = false,
  children,
}) => {
  return (
    <div {...(isAriaLivePolite && { 'aria-live': 'polite' })} css={[containerCSS(fullscreen, small), customCSS]}>
      {children}
    </div>
  );
};

export default Container;
