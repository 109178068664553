import type { FC } from 'react';
import type { ScoreCardContentProps } from './ScoreCardContent.type.ts';
import { Gauge } from '@mui/x-charts/Gauge';
import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import { ScoreGaugeType } from '../ScoreCard/ScoreCard.type.ts';
import { GAUGE_LABELS_NEGATIVE, GAUGE_LABELS_POSITIVE, getGaugeLabel } from '../ScoreCard/ScoreCard.util.ts';
import { gaugeContainerCSS, gaugeCSS, gaugeTextCSS, infoMessageCSS } from './ScoreCardContent.style.ts';

const ScoreCardContent: FC<ScoreCardContentProps> = ({ score, scoreMax, scoreGaugeType, tooltip }) => {
  return (
    <>
      <div css={gaugeContainerCSS}>
        <Gauge
          sx={gaugeCSS(score, scoreMax, scoreGaugeType)}
          height={110}
          value={score <= 50 ? 50 : score}
          valueMax={scoreMax}
          startAngle={-110}
          endAngle={110}
          text=""
          cornerRadius="50%"
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        />
        <div css={gaugeTextCSS}>
          {getGaugeLabel(
            score,
            scoreMax,
            scoreGaugeType === ScoreGaugeType.Positive
              ? GAUGE_LABELS_POSITIVE
              : GAUGE_LABELS_NEGATIVE,
          )}
        </div>
      </div>
      <div css={infoMessageCSS}>
        <span>Learn more about how we calculate this score</span>
        <TooltipQuestionButton size={16} content={tooltip} placement="bottom" />
      </div>
    </>
  );
};

export default ScoreCardContent;
