import type { PersonLoan } from '../../../types/person-loan.type';
import type { Quote } from '../../../types/quote.type';
import { PaymentMethod } from '../../../types/person-loan.type';

interface LoanPaymentOption {
  loans?: PersonLoan[];
  assets_gids: Quote['assets_gids'];
}

export function getLoanPaymentOption({ loans, assets_gids }: LoanPaymentOption) {
  return loans?.find(loan => loan.home?.gid === assets_gids?.[0])?.payment_method === PaymentMethod.Escrow
    ? 'Payment through escrow'
    : 'Direct bill payment';
}
