import type { FC } from 'react';
import type { PageContainerProps } from './PageContainer.type';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import useAnalytics from '../../hooks/use-analytics.ts';
import Container from '../../shared/components/Container/Container.tsx';
import Footer from '../../shared/components/Footer/Footer.tsx';
import { AnalyticLocation } from '../../types/analytics.type.ts';
import PortalHeader from '../PortalHeader/PortalHeader.tsx';
import { appContainerCSS, containerInnerCSS } from './PageContainer.style';

const PageContainer: FC<PageContainerProps> = ({
  customCSS,
  pageKey,
  children,
  showBackButton = false,
  footerCopy,
}) => {
  const { data: settingsData } = useSettingsQuery();
  const { track } = useAnalytics();

  const onPhoneClicked = (): void => {
    track('Phone Conversation Initiated', {
      page: pageKey,
      location: AnalyticLocation.Footer,
    });
  };

  return (
    <div css={appContainerCSS}>
      <PortalHeader pageKey={pageKey} customCSS={customCSS?.header} showBackButton={showBackButton} />
      <Container customCSS={[containerInnerCSS(showBackButton), customCSS?.innerContainer]}>{children}</Container>
      <Footer
        phone={settingsData?.phone_number}
        inverted
        onSocialLinkClick={onPhoneClicked}
        customCSS={customCSS?.footer}
        copy={footerCopy}
      />
    </div>
  );
};

export default PageContainer;
