import type { FC } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import { customPageContainerCSS } from './ScheduleCallLayout.style.ts';
import ScheduleCallPage from './ScheduleCallPage/ScheduleCallPage';

const ScheduleCallLayout: FC = () => {
  useTrackPage(AnalyticPageKey.ScheduleCall);

  return (
    <PageContainer
      pageKey={AnalyticPageKey.ScheduleCall}
      customCSS={{ innerContainer: customPageContainerCSS }}
      showBackButton
    >
      <ScheduleCallPage />
    </PageContainer>
  );
};

export default ScheduleCallLayout;
