import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';

export const inputWrapperCSS = css`
  position: relative;
`;

export function inputCSS(
  isInvalid?: boolean,
  isDisabled?: boolean,
  hasPrefix = false,
  isPrefilled = false,
  dense = false,
): SerializedStyles {
  return css`
    background-color: ${THEME.color.white};
    border: 1px solid ${THEME.color.black};
    border-radius: ${THEME.size.borderRadius}px;
    box-sizing: border-box;
    color: ${THEME.color.black};
    font-family: ${THEME.typography.fontFamily};
    font-size: ${THEME.typography.fontSize}px;
    height: ${dense ? THEME.size.controlHeightSmall : THEME.size.controlHeight}px;
    outline: none;
    padding-left: ${dense ? THEME.size.inputPaddingHorizontalSmall : THEME.size.inputPaddingHorizontal}px;
    padding-right: ${dense ? THEME.size.inputPaddingHorizontalSmall : THEME.size.inputPaddingHorizontal}px;
    width: 100%;
    transition:
      border-color 0.2s ease,
      color 0.2s ease;
    position: relative;
    z-index: 1;
    font-variant: lining-nums;
    appearance: none;

    &:hover {
      border-color: ${THEME.color.azure62};
    }

    ${isPrefilled
    && css`
      border-color: ${THEME.color.azure50};
      box-shadow: inset 0 0 0 1px ${THEME.color.azure50};
    `}

    ${isInvalid
    && css`
      &,
      &:hover,
      &:focus {
        padding-left: ${dense ? THEME.size.inputPaddingHorizontalSmall - 1 : THEME.size.inputPaddingHorizontal - 1}px;
        padding-right: ${dense ? THEME.size.inputPaddingHorizontalSmall - 1 : THEME.size.inputPaddingHorizontal - 1}px;
        color: ${THEME.color.errorRed};
        border-color: ${THEME.color.errorRed};
        box-shadow: inset 0 0 0 1px ${THEME.color.errorRed};
      }
    `};

    ${isDisabled
    && css`
      background-color: ${THEME.color.gray10};
      cursor: not-allowed;
    `}
    &:focus {
      border-color: ${THEME.color.black};
      box-shadow: none;
    }

    &:focus + .input-focus-helper {
      border-color: ${isInvalid ? THEME.color.errorRed : THEME.color.azure50};
    }

    &:focus ~ .input-icon {
      color: ${isInvalid ? THEME.color.errorRed : THEME.color.azure50};
    }

    ${hasPrefix
    && css`
      &,
      &:hover,
      &:focus {
        padding-left: 30px;
        padding-right: 20px;
      }
    `};
  `;
}

export const focusHelperCSS = css`
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
  z-index: 0;
`;
