import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';

export const containerCSS = css`
  margin-bottom: 80px;
`;

export const marketingWrapperCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;

  ${mq[2]} {
    padding-top: 80px;

    ::before {
      content: '';
      background: linear-gradient(180deg, #F2F2F2 0%, #FFF 49.83%);
      top: 0;
      right: calc((100vw - 100%) / -2);
      left: calc((100vw - 100%) / -2);
      height: 173px;
      position: absolute;
      z-index: -1;
    }
  }
`;

export const sectionHeadingCSS = css`
  margin: 0;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 40px;

  ${mq[2]} {
    font-size: 40px;
    line-height: 52px;
    text-align: start;
  }
`;

export const centerTextCSS = css`
  ${mq[2]} {
    text-align: center;
  }
`;

export const cardsWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  ${mq[1]} {
    > * {
      min-width: 300px;
      flex: 1 1 calc(50% - 20px);
    }
  }
`;

export const customButtonCSS = css`
  margin: 32px auto 40px;
  max-width: 480px;
  width: 100%;

  ${mq[2]} {
    margin-top: 56px;
    margin-bottom: 120px;
  }
`;

export const howItWorksContainerCSS = css`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;

  ${mq[2]} {
    gap: 80px;
    margin-bottom: 80px;
  }
`;

export const howItWorksImgCSS = css`
  max-width: 100%;
`;

export const logoCSS = css`
  height: 30px;
  display: block;
  margin: 20px auto 0;
`;

export const sectionWrapperCSS = css`
  margin: 32px 0 44px;
  ${mq[1]} {
    margin-top: 56px;
  }
`;

export const formWrapperCSS = css`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mq[1]} {
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
  }
`;

export const leftColCSS = css`
  ${mq[1]} {
    width: calc(50% - 20px);
  }
`;

export const formFooterCSS = css`
  ${mq[1]} {
    max-width: calc(50% - 20px);
  }
`;

export const rightColCSS = css`
  align-content: end;
  ${mq[1]} {
    width: calc(50% - 20px);
  }
`;

export const lightBulbImgCSS = css`
  max-width: 430px;
  display: block;
  margin: 0 auto;
`;
