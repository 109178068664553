import type { FC } from 'react';

import type { ProposalCardProps } from './ProposalCard.type';
import { containerCSS } from './ProposalCard.style';

const ProposalCard: FC<ProposalCardProps> = ({
  customCSS,
  children,
  backgroundColor = 'transparent',
  isTextCard = false,
}) => {
  return <div css={[containerCSS(backgroundColor, isTextCard), customCSS]}>{children}</div>;
};

export default ProposalCard;
