import type { FC } from 'react';
import type { HowItWorksProps } from './HowItWorksCard.type';
import { containerCSS, descriptionCSS, titleCSS } from './HowItWorksCard.style';

const HowItWorksCard: FC<HowItWorksProps> = ({ customCSS, icon, title, description, iconOrientation = 'left' }) => {
  const isIconLeft = iconOrientation === 'left';

  return (
    <div css={[containerCSS(isIconLeft), customCSS]}>
      {isIconLeft && icon}
      <div>
        <h3 css={titleCSS}>{title}</h3>
        <p css={descriptionCSS}>{description}</p>
      </div>
      {!isIconLeft && icon}
    </div>
  );
};

export default HowItWorksCard;
