import type { FC } from 'react';

import type { QuestionProps } from '../Question.type';
import { QuestionWrapper } from '../../QuestionWrapper/QuestionWrapper';
import QuestionInput from '../QuestionInput/QuestionInput.tsx';

const TextInputQuestion: FC<QuestionProps> = ({
  questionKey,
  placeholder,
  isDisabled,
  validations,
  label,
  hideLabel,
  description,
  icon,
  hint,
  gridWidth,
  dense,
  trackingForbidden,
  type = 'text',
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hideLabel={hideLabel}
    hint={hint}
    description={description}
    icon={icon}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      name: questionKey,
      inputId: questionKey,
      placeholder,
      isDisabled,
      validations: isDisabled ? [] : validations,
      trackingForbidden,
      dense,
      type,
    }}
    inputComponent={QuestionInput}
  />
);

export default TextInputQuestion;
