import type { SerializedStyles } from '@emotion/react';
import type { InfoPanelType } from './InfoPanel.type.ts';
import { css } from '@emotion/react';
import THEME from '../../styles/theme.ts';

function getPanelBackgroundColor(type?: InfoPanelType): string {
  switch (type) {
    case 'positive':
      return THEME.color.positiveGreen94;
    case 'warning':
      return THEME.color.warningYellow94;
    case 'negative':
      return THEME.color.errorRed96;
    default:
      return THEME.color.azure95;
  }
}

export function containerCSS(type?: InfoPanelType): SerializedStyles {
  return css`
    background-color: ${getPanelBackgroundColor(type)};
    border-radius: ${THEME.size.borderRadiusInner}px;
    padding: 12px 12px 12px 40px;
    position: relative;
    
    a {
      border-bottom: unset;
      text-decoration: none;
    }

    a, a :visited, a :active {
      color: ${THEME.color.azure50};
    }
  `;
}

export const iconCSS = css`
  position: absolute;
  left: 12px;
`;

export const negativeIconCSS = css`
  ${iconCSS};
  color: ${THEME.color.errorRed};
`;

export const positiveIconCSS = css`
  ${iconCSS};
  color: ${THEME.color.positiveGreen27};
`;

export const warningIconCSS = css`
  ${iconCSS};
  color: ${THEME.color.carrot};
`;

export const defaultIconCSS = css`
  ${iconCSS};
  color: ${THEME.color.azure50};
`;
