export const TEST_ID = {
  ADDRESS: 'address',
  GRAPH_LEGEND_LABEL_1: 'graph-legend-label-1',
  GRAPH_LEGEND_VALUE_1: 'graph-legend-value-1',
  GRAPH_LEGEND_LABEL_2: 'graph-legend-label-2',
  GRAPH_LEGEND_VALUE_2: 'graph-legend-value-2',
  BLOG_POST: 'blogpost_block',
  QUOTES_LIST: 'quotes-list',
  INSIGHT_QUOTES_COUNT: 'insight-quotes-count',
  QUOTE_PREMIUM: 'quote-premium',
};
