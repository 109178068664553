import type { FC } from 'react';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';

import { Global } from '@emotion/react';
import * as Sentry from '@sentry/react';

import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';

import useAccountQuery from '../../api/account/use-account-query.ts';
import useQuotesQuery from '../../api/quotes/use-quotes-query.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import ROUTES from '../../constants/routes.ts';
import { useAnalyticsSessionGidCheck } from '../../hooks/use-analytics-session-gid-check.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import Notification from '../../shared/components/Notification/Notification.tsx';
import { useHistoryStore } from '../../store/history-store.ts';
import { globalCSS } from '../../styles/global.style.ts';
import { QuotingStatus } from '../../types/quote.type.ts';
import analytics from '../../utils/analytics.util.ts';
import fullStoryUtil from '../../utils/full-story.util.ts';
import isGid from '../../utils/is-gid.util.ts';
import SessionExpiredModal from '../SessionExpiredModal/SessionExpiredModal.tsx';
import SessionRootInner from './SessionRootInner.tsx';

const SessionRoot: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const accountGidParam = isGid(gid) ? gid : null;
  const { data: accountData } = useAccountQuery(accountGidParam);
  const { data: quotesData } = useQuotesQuery(accountGidParam, accountData?.account.data_collection_completed);
  const [notificationMessage, setNotificationMessage] = useState<null | string>(null);
  const [isPendingShown, setIsPendingShown] = useState(false);

  const accountGid = accountData?.account.gid;
  const accountTestMode = accountData?.account.test_mode;
  const personGid = accountData?.account.person_gid;
  const quotesRequestStatus = quotesData?.quotes_request_status;

  const localStorageSessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

  const { sessionGid } = useAnalyticsSessionGidCheck(localStorageSessionGid);

  const { pathname } = useLocation();

  const { addToHistory } = useHistoryStore();

  useEffect(() => {
    addToHistory(pathname);
  }, [addToHistory, pathname]);

  useEffect(() => {
    if (quotesRequestStatus === QuotingStatus.Pending) {
      setNotificationMessage('We are looking for a quote');
      setIsPendingShown(true);
    }
  }, [quotesRequestStatus]);

  useEffect(() => {
    if (isPendingShown && quotesRequestStatus === QuotingStatus.Finished) {
      setNotificationMessage('Quotes are ready');
    }
  }, [isPendingShown, quotesRequestStatus]);

  useEffect(() => {
    if (accountGid && personGid) {
      analytics.identify(personGid, {
        email: analytics.generateTrackEmail(personGid),
        account_gid: accountGid,
        test_mode: accountTestMode || false,
      });

      fullStoryUtil.identify(personGid, {
        email: analytics.generateTrackEmail(personGid),
        accountGid,
        testMode: accountTestMode || false,
      });

      Sentry.setUser({ email: analytics.generateTrackEmail(personGid), test_mode: accountTestMode });
    }
  }, [accountGid, personGid, accountTestMode]);

  const onNotificationClose = () => {
    setNotificationMessage(null);
  };

  if (!accountGidParam) {
    return <Navigate to={ROUTES.problem} />;
  }

  return (
    <>
      <Notification open={!!notificationMessage} message={notificationMessage || ''} onClose={onNotificationClose} />
      <Global styles={globalCSS} />
      {
        sessionGid
          ? (
              <>
                <SessionExpiredModal />
                <SessionRootInner />
              </>
            )
          : <FullPageLoader />
      }
    </>
  );
};

export default SessionRoot;
