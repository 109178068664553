import type { FC } from 'react';
import type { NotificationProps } from './Notification.type.ts';
import Snackbar from '@mui/material/Snackbar';
import Button from '../Button/Button.tsx';
import { buttonCSS, containerCSS } from './Notification.style.ts';

const Notification: FC<NotificationProps> = ({ open, message, autoHideDuration = 6000, onClose }) => {
  return (
    <Snackbar
      sx={containerCSS}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      action={<Button customCSS={buttonCSS} variant="secondary" size="small" onClick={onClose}>Dismiss</Button>}
    />
  );
};

export default Notification;
