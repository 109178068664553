import type { QuestionProps } from '../Question/Question.type';
import type { EditableFields, PolicyData, PolicyDataFields } from './CollectChartData.type';
import { ValidationType } from '../../types/validation.type';
import { REQUIRED_VALIDATION } from '../../utils/question-validations.util';
import { QuestionType } from '../Question/Question.type';

const CURRENT_PREMIUM_VALIDATION = [
  {
    key: ValidationType.Min,
    value: 300,
    message: 'Please verify your current premium.',
  },
  {
    key: ValidationType.Max,
    value: 30000,
    message: 'Please verify your current premium.',
  },
];

const CURRENT_DWELLING_COVERAGE_VALIDATION = {
  key: ValidationType.Min,
  value: 9000,
  message: 'Please verify your Dwelling Coverage.',
};

const POLICY_EXPIRATION_DATE_VALIDATION = [
  {
    key: ValidationType.MinDaysFromNow,
    value: 1,
    message: 'Must be in the next 12 months.',
  },
  {
    key: ValidationType.MaxYears,
    value: 1,
    message: 'Must be in the next 12 months.',
  },
];

export const CHART_FORM_SCHEMA = [
  {
    type: QuestionType.Number,
    questionKey: 'premium',
    label: 'Current Premium',
    placeholder: '$0,000',
    validations: [REQUIRED_VALIDATION, ...CURRENT_PREMIUM_VALIDATION],
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Date,
    questionKey: 'expiration_date',
    label: 'Policy expiration date',
    validations: [REQUIRED_VALIDATION, ...POLICY_EXPIRATION_DATE_VALIDATION],
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Number,
    questionKey: 'limit_value',
    label: 'Current Dwelling Coverage',
    placeholder: '$0,000',
    validations: [REQUIRED_VALIDATION, CURRENT_DWELLING_COVERAGE_VALIDATION],
    gridWidth: 12,
    dense: true,
    trackingForbidden: true,
  },
] as const satisfies QuestionProps[];

// will return keys that we can edit
export function getAllowedKeysToUpdate(
  data: PolicyData,
  change_sources: Partial<Record<PolicyDataFields, string | undefined>>,
): EditableFields {
  return (Object.keys(data) as Array<keyof typeof data>).filter((d) => {
    const changeSource = change_sources[d] === 'customer_portal';

    /*
      we allow to edit fields only if:
        - we have already edited data for this field in customer_portal
        - we don`t have data for this field
    */
    return changeSource || !data[d];
  });
}
