import type { FC } from 'react';

import type { HamburgerMenuProps } from './HamburgerMenu.type';
import { containerCSS } from './HamburgerMenu.style';

const HamburgerMenu: FC<HamburgerMenuProps> = ({ customCSS, isOpen, onClick }) => {
  return (
    <button
      css={[containerCSS(isOpen), customCSS]}
      aria-label="Menu"
      aria-controls={isOpen ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? 'true' : undefined}
      onClick={onClick}
      type="button"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default HamburgerMenu;
