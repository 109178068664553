import type { FC } from 'react';
import type { ProposalAutoQuoteInfoProps } from './ProposalAutoQuoteInfo.type';

import { Fragment } from 'react';
import usePersonAssetsQuery from '../../../api/proposal/use-person-assets-query';
import usePersonQuery from '../../../api/proposal/use-person-query';
import useRelatedPeopleQuery from '../../../api/proposal/use-related-people-query';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import { CoverageKey } from '../../../types/quote.type';
import { moneyFormatterWithoutLastZeros } from '../ProposalLayout.util';
import {
  autoDescriptionCSS,
  autoTitleCSS,
  boldTextCSS,
  carrierLineCSS,
  containerCSS,
  coverageExcludedValueCSS,
  coverageLineCSS,
  coverageValueCSS,
  dividerLineCSS,
  headerCSS,
  headerLineCSS,
} from './ProposalAutoQuoteInfo.style';
import {
  buildVehicleTitle,
  generateAutoDescription,
  getAssetDeductibleByCoverageKey,
  getAssetLimitByCoverageKey,
  getLimitByCoverageKey,
} from './ProposalAutoQuoteInfo.util';

const ProposalAutoQuoteInfo: FC<ProposalAutoQuoteInfoProps> = ({ quote, personGid }) => {
  const { data: assets } = usePersonAssetsQuery({ personGid, assetGids: quote.assets_gids });
  const { data: relatedPeople } = useRelatedPeopleQuery({ personGid });
  const { data: person } = usePersonQuery({ personGid });

  const propertyDamage = getLimitByCoverageKey(quote.coverages, CoverageKey.PropertyDamage);
  const biPerPerson = getLimitByCoverageKey(quote.coverages, CoverageKey.BiPerPerson);
  const biPerAccident = getLimitByCoverageKey(quote.coverages, CoverageKey.BiPerAccident);
  const uninsuredMpdPerPerson = getLimitByCoverageKey(quote.coverages, CoverageKey.UninsuredMbiPerPerson);
  const uninsuredMpdPerAccident = getLimitByCoverageKey(quote.coverages, CoverageKey.UninsuredMbiPerAccident);
  const uninsuredMpd = getLimitByCoverageKey(quote.coverages, CoverageKey.UninsuredMpd);
  const medPay = getLimitByCoverageKey(quote.coverages, CoverageKey.MedicalPayments);
  const pip = getLimitByCoverageKey(quote.coverages, CoverageKey.PersonalInjuryProtection);
  const coinsuredNames = relatedPeople
    ?.filter(person => quote.coinsureds_gids?.includes(person.gid))
    .map(person => person.first_name);

  const insuredNames = [person?.first_name, ...(coinsuredNames || [])].filter(Boolean);
  return (
    <ProposalCard isTextCard>
      <div css={containerCSS}>
        <p css={headerCSS}>Auto insurance details</p>
        <p css={autoDescriptionCSS}>{generateAutoDescription(assets?.vehicles)}</p>
        <div css={carrierLineCSS}>
          <p css={boldTextCSS}>Carrier</p>
          <img src={quote.carrier.logo_url!} alt={quote.carrier.key!} width="120" />
        </div>
        {insuredNames && insuredNames.length > 0 && (
          <div css={coverageLineCSS}>
            <p css={boldTextCSS}>Insured drivers</p>
            <p>{insuredNames?.join(', ')}</p>
          </div>
        )}
        <div css={headerLineCSS}>
          <p css={boldTextCSS}>Matic recommended coverages</p>
          <p css={boldTextCSS}>Limit</p>
        </div>
        {(biPerPerson || biPerAccident) && (
          <div css={coverageLineCSS}>
            <p>Bodily injury liability</p>
            <p>
              {biPerPerson && (
                <p css={coverageValueCSS}>
                  Each person
                  {biPerPerson}
                </p>
              )}
              {biPerAccident && (
                <p css={coverageValueCSS}>
                  Each accident
                  {biPerAccident}
                </p>
              )}
            </p>
          </div>
        )}
        {propertyDamage && (
          <div css={coverageLineCSS}>
            <p>Property damage</p>
            <p>
              Each accident
              {propertyDamage}
            </p>
          </div>
        )}
        {(uninsuredMpdPerPerson || uninsuredMpdPerAccident) && (
          <div css={coverageLineCSS}>
            <p>Uninsured and underinsured motorist injury liability</p>
            <p>
              {uninsuredMpdPerPerson && (
                <p css={coverageValueCSS}>
                  Each person
                  {uninsuredMpdPerPerson}
                </p>
              )}
              {uninsuredMpdPerAccident && (
                <p css={coverageValueCSS}>
                  Each accident
                  {uninsuredMpdPerAccident}
                </p>
              )}
            </p>
          </div>
        )}
        {uninsuredMpd && (
          <div css={coverageLineCSS}>
            <p>Uninsured and underinsured motorist property damage</p>

            <p css={coverageValueCSS}>
              Each accident
              {uninsuredMpd}
            </p>
          </div>
        )}
        {medPay && (
          <div css={coverageLineCSS}>
            <p>Medical payments</p>
            <p css={coverageValueCSS}>{medPay}</p>
          </div>
        )}
        {pip && (
          <div css={coverageLineCSS}>
            <p>Personal injury protection</p>
            <p css={coverageValueCSS}>{pip}</p>
          </div>
        )}
        {assets?.vehicles
        && assets.vehicles.length > 0
        && assets.vehicles.map((vehicle) => {
          const collisionDeductible = getAssetDeductibleByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.Collision,
            coverages: quote.coverages,
          });
          const comprehensiveDeductible = getAssetDeductibleByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.Comprehensive,
            coverages: quote.coverages,
          });
          const towing = getAssetLimitByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.Towing,
            coverages: quote.coverages,
          });
          const fullGlass = getAssetLimitByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.FullGlass,
            coverages: quote.coverages,
          });
          const rentalMaxAmount = getAssetLimitByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.RentalMaxAmount,
            coverages: quote.coverages,
          });

          return (
            <Fragment key={vehicle?.gid}>
              <p css={autoTitleCSS}>{buildVehicleTitle(vehicle)}</p>
              {collisionDeductible && (
                <div css={coverageLineCSS}>
                  <p>Collision deductible</p>
                  <p>{collisionDeductible}</p>
                </div>
              )}
              {comprehensiveDeductible && (
                <div css={coverageLineCSS}>
                  <p>Comprehensive deductible</p>
                  <p>{comprehensiveDeductible}</p>
                </div>
              )}
              {towing && (
                <div css={coverageLineCSS}>
                  <p>Roadside assistance</p>
                  <p css={towing === 'Included' ? coverageValueCSS : coverageExcludedValueCSS}>{towing}</p>
                </div>
              )}
              {fullGlass && (
                <div css={coverageLineCSS}>
                  <p>Full glass</p>
                  <p css={fullGlass === 'Included' ? coverageValueCSS : coverageExcludedValueCSS}>{fullGlass}</p>
                </div>
              )}

              {rentalMaxAmount && (
                <div css={coverageLineCSS}>
                  <p>Rental car reimbursement</p>

                  <p css={rentalMaxAmount === 'Included' ? coverageValueCSS : coverageExcludedValueCSS}>
                    {rentalMaxAmount}
                  </p>
                </div>
              )}
            </Fragment>
          );
        })}

        <div css={dividerLineCSS} />
        <div css={coverageLineCSS}>
          <p css={headerCSS}>
            {quote.payment_options?.[0].term_months === 12 ? 'Yearly' : '6 months'} premium
          </p>
          <p css={headerCSS}>{moneyFormatterWithoutLastZeros(quote.premium)}</p>
        </div>
      </div>
    </ProposalCard>
  );
};

export default ProposalAutoQuoteInfo;
