import type { FC } from 'react';

import type { DateInputProps } from './DateInput.type';
import { Controller } from 'react-hook-form';

import { PatternFormat } from 'react-number-format';
import { FS_SENSITIVE_DATA_CLASS } from '../../../constants/full-story.ts';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from '../../../shared/components/Input/Input.style.ts';
import Input from '../../../shared/components/Input/Input.tsx';
import { isValueSpecificType } from '../../../utils/answers.util.ts';
import { normalizeDateValue, parseDateValue } from '../../../utils/date.util.ts';
import { getValidationRules, hardcodedValidations } from '../../../utils/question-validations.util.ts';

const DateInput: FC<DateInputProps> = ({
  name,
  inputId,
  hasError = false,
  isDisabled,
  placeholder,
  mask,
  validations,
  trackingForbidden = false,
  dense = false,
}) => {
  return (
    <Controller
      name={name}
      rules={getValidationRules(validations, {
        isValidDate: hardcodedValidations.isValidDate,
      })}
      render={({ field: { onChange, value, onBlur, name } }) => (
        <div css={inputWrapperCSS}>
          <PatternFormat
            id={inputId}
            className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
            css={inputCSS(hasError, isDisabled, false, false, dense)}
            type="text"
            format={mask}
            mask="_"
            name={name}
            customInput={Input}
            value={parseDateValue(isValueSpecificType<string>(value, ['string']) ? value : '')}
            disabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(e) => {
              const value = normalizeDateValue(e.target.value);
              onChange(value);
              if (e.target.value !== value) {
                onBlur();
              }
            }}
          />
          <div css={focusHelperCSS} className="input-focus-helper" />
        </div>
      )}
    />
  );
};

export default DateInput;
