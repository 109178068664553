import type { FC } from 'react';
import type { CheckInProps } from './CheckIn.type';
import Arrow from '../../../assets/svg/sign-up/arrow.svg?react';
import EllipseIcon from '../../../assets/svg/sign-up/ellipse.svg?react';
import TriangleIcon from '../../../assets/svg/sign-up/triangle.svg?react';
import Button from '../../../shared/components/Button/Button';
import { Card } from '../../../shared/components/Card/Card';
import {
  arrowCSS,
  checkInButtonCSS,
  checkInCardCSS,
  checkInDescriptionCSS,
  checkInTitleCSS,
  ellipseCSS,
  triangleCSS,
} from './CheckIn.style';

const CheckIn: FC<CheckInProps> = ({ onActionClicked }) => {
  return (
    <>
      <Arrow css={arrowCSS} />
      <Card customCSS={checkInCardCSS}>
        <h2 css={checkInTitleCSS}>You sign up. We’ll check in.</h2>
        <p css={checkInDescriptionCSS}>Get started with automatic home insurance monitoring today.</p>

        <Button size="large" customCSS={checkInButtonCSS} onClick={onActionClicked}>
          Sign Up Now
        </Button>
        <EllipseIcon css={ellipseCSS} />
        <TriangleIcon css={triangleCSS} />
      </Card>
    </>
  );
};

export default CheckIn;
