import type { HTTPResponse } from '../../types/api.type';
import type { AvailableCarriersResponse } from '../../types/carrier.ts';
import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import api from '../api';
import { AvailableCarrierSchema } from './available-carriers.schema.ts';

function useAvailableCarriersQuery(gid: string, appointed: boolean) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.availableCarriers, gid, appointed],
    queryFn: async () =>
      await api
        .get<HTTPResponse<AvailableCarriersResponse>>(API_PATH.availableCarriers.replace(':gid', gid), {
          params: { appointed },
        })
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          return checkResponseType<AvailableCarriersResponse>(res.data, AvailableCarrierSchema);
        }),
  });
}

export default useAvailableCarriersQuery;
