import type { HowItWorksProps } from './HowItWorksCard/HowItWorksCard.type';
import PersonWithChatBubbleIcon from '../../assets/svg/person-with-chat-bubble.svg?react';
import ChartsIcon from '../../assets/svg/sign-up/charts.svg?react';
import ChatBubblesIcon from '../../assets/svg/sign-up/chat-bubbles.svg?react';
import CheckmarkWithRowIcon from '../../assets/svg/sign-up/checkmark-with-row.svg?react';
import DisplayIcon from '../../assets/svg/sign-up/display.svg?react';
import MobileProtectionScoreIcon from '../../assets/svg/sign-up/mobile-protection-score.svg?react';
import PersonWithScreenIcon from '../../assets/svg/sign-up/person-with-screen.svg?react';
import SpeedometerWithShieldIcon from '../../assets/svg/sign-up/speedometer-with-shield.svg?react';
import { howItWorksImgCSS } from './SignUpLayout.style.ts';

export const TOOLS_TO_OPTIMIZE_DATA = [
  {
    key: 'rate-review',
    icon: <ChartsIcon />,
    description:
      <>
        With Matic’s RateReview<sup>®</sup> monitoring service, we proactively shop 40+
        A-rated carriers and alert you if we find a lower rate.
      </>,
    title: <>RateReview<sup>®</sup> Monitoring</>,
  },
  {
    key: 'protection-snapshot',
    icon: <SpeedometerWithShieldIcon />,
    description: `Get an easy-to-understand view of how your home is protected. 
    See your personalized risk and protection scores along with simplified summaries 
    of your coverage to help you identify gaps.`,
    title: 'Protection Snapshot',
  },
  {
    key: 'home-replacement-cost',
    icon: <ChatBubblesIcon />,
    description: `See up-to-date estimates of the cost to rebuild your home, which can help 
    you decide if you're over-insured, under-insured, or just right. Plus, get alerts 
    if we detect changes that could affect this amount.`,
    title: 'Home Replacement Cost Calculator',
  },
  {
    key: 'carrier-options',
    icon: <CheckmarkWithRowIcon />,
    description: `Stay informed of carriers offering coverage for your home. 
    We'll notify you if new options become available in our network.`,
    title: 'Carrier Options',
  },
];

export const HOW_IT_WORKS_DATA: HowItWorksProps[] = [
  {
    description: 'Provide a few details about yourself to sign up. Then, verify your account via email.',
    icon: <PersonWithChatBubbleIcon css={howItWorksImgCSS} width={360} />,
    title: 'Create your account',
  },
  {
    description:
      <>
        Log in and fill out an easy online form with information about your property.
        This helps us personalize your coverage insights and set up proactive
        rate monitoring through RateReview<sup>®</sup>.
      </>,
    icon: <DisplayIcon css={howItWorksImgCSS} width={360} />,
    iconOrientation: 'right',
    title: 'Build your profile',
  },
  {
    description: `View your personalized risk and protection scores to evaluate your current coverage and how 
    insurers might assess your home’s risk level.`,
    icon: <MobileProtectionScoreIcon css={howItWorksImgCSS} width={360} />,
    title: 'Review your Protection Snapshot',
  },
  {
    description: `We proactively search a network of 40+ A-rated carriers for lower rates 
    and notify you about changes that could impact your recommended coverage.`,
    icon: <PersonWithScreenIcon css={howItWorksImgCSS} width={360} />,
    iconOrientation: 'right',
    title: 'Get ongoing price and coverage checks',
  },
];
