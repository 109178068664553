import { PolicyType } from '../../types/quote.type';
import { moneyFormatter } from '../../utils/formatters.util';

export const policyTypeLabels = {
  [PolicyType.Home]: 'Home',
  [PolicyType.Auto]: 'Auto',
  [PolicyType.Renters]: 'Renters',
  [PolicyType.Condo]: 'Condo',
  [PolicyType.Fire]: 'Dwelling/Fire',
};

export function moneyFormatterWithoutLastZeros(value: number) {
  const stringWithPossibleZeros = `${moneyFormatter(value, false, 2)}`;

  if (stringWithPossibleZeros.includes('.00')) {
    return stringWithPossibleZeros.replace('.00', '');
  }

  return stringWithPossibleZeros;
}
