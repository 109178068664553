import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export const formInnerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 12px;
  margin-bottom: 12px;
  margin-right: -16px;

  & > div {
    margin: 0;
  }
`;

export function frameWrapperCustomCSS(isPending: boolean): SerializedStyles {
  return css`
  --side-padding: 16px;
  padding: 16px;


  ${isPending && `
    --side-padding: 64px;
    padding: 64px;
    text-align: center;
  `}
`;
}
