import { css } from '@emotion/react';
import { linkCSS } from '../../../styles/common.style.ts';
import THEME from '../../../styles/theme.ts';

export const paragraphCSS = css`
  font-size: 12px;
  line-height: 16px;
  color: ${THEME.color.gray60};
  margin-top: 20px;

  ${linkCSS};
`;

export const customButtonCSS = css`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
`;
