import type { FC } from 'react';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import type { CarriersLockListProps } from './CarriersLockList.type.ts';

import { useState } from 'react';
import { useParams } from 'react-router';
import useAvailableCarriersQuery from '../../../api/available-carriers/use-available-carriers-query.ts';
import ListLoadingSkeleton from '../../../components/ListLoadingSkeleton/ListLoadingSkeleton.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import { buttonCSS, listContainerCSS } from '../CarrierOptionsList/CarrierOptionsList.style.ts';
import { sortBySupported } from '../CarrierOptionsList/CarrierOptionsList.util.ts';
import QuoteItem from '../QuoteItem/QuoteItem.tsx';
import { QuoteStatus } from '../QuoteItem/QuoteItem.type.ts';

const CarriersLockList: FC<CarriersLockListProps> = ({ optionsLimit, handleQuoteUnlock }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: appointedCarriersData } = useAvailableCarriersQuery(gid, true);
  const { data: restCarriersData, isPending: isRestCarriersPending } = useAvailableCarriersQuery(gid, false);
  const carriers = [...appointedCarriersData?.carriers || [], ...restCarriersData?.carriers || []];
  const allOptionsLength = carriers.length;

  const [showAllCarriers, setShowAllCarriers] = useState(false);

  const showExpandButton = !showAllCarriers
    && (allOptionsLength > optionsLimit
      || carriers.some(carrier => !carrier.supported));

  const handleShowMoreClick = () => {
    setShowAllCarriers(true);
  };

  return (
    <>
      <div css={listContainerCSS}>
        {carriers
          .sort((a, b) => sortBySupported(!!a.supported, !!b.supported))
          .filter(carrier => showAllCarriers || carrier.supported)
          .slice(0, showAllCarriers ? allOptionsLength : optionsLimit)
          .map((carrier) => {
            return (
              <QuoteItem
                key={carrier.key}
                carrier={carrier}
                quote={{ status: QuoteStatus.Unknown }}
                onQuoteUnlock={handleQuoteUnlock}
              />
            );
          })}

        {showAllCarriers && isRestCarriersPending && <ListLoadingSkeleton numberOfItems={10} />}
      </div>

      {(showExpandButton
        || (!showAllCarriers && appointedCarriersData && isRestCarriersPending)) && (
        <Button customCSS={buttonCSS} variant="secondary" fullWidth onClick={handleShowMoreClick}>
          View full carrier list
        </Button>
      )}
    </>
  );
};

export default CarriersLockList;
