import type { HTTPResponse } from '../../types/api.type.ts';
import type { RecentPolicyResponse } from '../../types/policy.type.ts';
import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import { checkResponseType } from '../../utils/error.util.ts';
import api from '../api.ts';
import { RecentPolicyResponseSchema } from './recent-policy.schema.ts';

export default function useRecentPolicyQuery(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.recentPolicy, gid],
    throwOnError: false,
    queryFn: async () =>
      await api
        .get<HTTPResponse<RecentPolicyResponse>>(API_PATH.recentPolicy().replace(':gid', gid))
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          return checkResponseType<RecentPolicyResponse>(res.data, RecentPolicyResponseSchema);
        }),
  });
}
