import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import THEME from '../../../styles/theme';

export const formInnerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -16px;

  [data-for=tooltip-address] {
    svg {
      path {
        fill: ${THEME.color.gray60};
      }
      circle {
        stroke: ${THEME.color.gray60};
      }
    }
  }
`;

export const titleCSS = css`
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 20px;
  text-align: center;
  
  ${mq[1]} {
    text-align: start;
  }

  ${mq[2]} {
    font-size: 48px;
    line-height: 60px;
    font-weight: 900;
  }
`;

export const descriptionCSS = css`
  margin-bottom: 32px;
  text-align: center;
  font-size: 20px;

  ${mq[1]} {
    text-align: start;
  }

  ${mq[3]} {
    font-size: 24px;
    line-height: 34px;
  }
`;
