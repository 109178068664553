import { useCallback, useEffect, useRef } from 'react';

import analyticsUtil from '../../../utils/analytics.util.ts';
import fullStoryUtil from '../../../utils/full-story.util.ts';

function useTrackProposalPage(pageKey: string) {
  const page = useCallback(
    (pageName: string): void => {
      analyticsUtil.page(pageName);
      fullStoryUtil.page(pageName);
    },
    [],
  );

  const pageViewedTracked = useRef(false);

  useEffect(() => {
    if (pageKey && !pageViewedTracked.current) {
      page(analyticsUtil.generatePageName(pageKey));
      pageViewedTracked.current = true;
    }
  }, [pageKey, page]);
}

export default useTrackProposalPage;
