import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import THEME from '../../../styles/theme';

export function addressOptionCSS(isFocused?: boolean, isSelected?: boolean): SerializedStyles {
  return css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  padding: 10px 20px;
  font-variant: lining-nums;

  ${isFocused
  && css`
    background-color: ${THEME.color.gray10};
  `}

  &:hover {
    background-color: ${THEME.color.gray10};
  }

  ${isSelected
  && css`
    &,
    &:hover {
      background-color: ${THEME.color.azure50};
      color: ${THEME.color.white};
    }
  `}
`;
}

export const entriesCSS = css`
  color: ${THEME.color.azure50};
`;

export const caretCSS = css`
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
`;
