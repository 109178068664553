import type { Ref } from 'react';
import type { InputProps } from './Input.type';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from './Input.style';

function Input({
  ref,
  hasError = false,
  hasPrefix = false,
  isPrefilled = false,
  dense = false,
  customCSS,
  customWrapperCSS,
  ...props
}: InputProps & { ref?: Ref<HTMLInputElement | null> }) {
  return (
    <div css={[inputWrapperCSS, customWrapperCSS]}>
      <input
        type="number"
        css={[inputCSS(hasError, props.disabled, hasPrefix, isPrefilled, dense), customCSS]}
        {...props}
        ref={ref}
      />
      <div css={focusHelperCSS} className="input-focus-helper" />
    </div>
  );
}

Input.displayName = 'Input';

export default Input;
