import type { FC } from 'react';

import type { CommentProps } from './Comment.type.ts';
import ChatIcon from '../../../assets/svg/chat.svg?react';
import THEME from '../../../styles/theme.ts';
import { containerCSS, iconCSS } from './Comment.style.ts';

const Comment: FC<CommentProps> = ({ content }) => {
  return (
    <div css={containerCSS}>
      <ChatIcon color={THEME.color.violet} css={iconCSS} />
      <p>{content}</p>
    </div>
  );
};

export default Comment;
