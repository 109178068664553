import type { FC } from 'react';
import type { QuestionLabelProps } from './QuestionLabel.type';
import { memo } from 'react';
import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import Wrapper from '../../../shared/components/Wrapper/Wrapper.tsx';
import { labelCSS } from './QuestionLabel.style';
import { QuestionLabelWrapper } from './QuestionLabel.type';

const QuestionLabel: FC<QuestionLabelProps> = ({
  customCSS,
  children,
  wrapperElement = QuestionLabelWrapper.Label,
  keyName,
  tooltipText,
}) => {
  const wrapperComponentProps = wrapperElement === QuestionLabelWrapper.Label ? { htmlFor: keyName } : {};

  return (
    <Wrapper
      tag={wrapperElement === QuestionLabelWrapper.Label ? 'label' : 'div'}
      css={[labelCSS, customCSS]}
      {...wrapperComponentProps}
    >
      {children}
      {tooltipText
      && <TooltipQuestionButton id={keyName} content={tooltipText} size={16} />}
    </Wrapper>
  );
};

export default memo(QuestionLabel);
