import type { FC } from 'react';
import type { SkeletonLoaderProps } from './SkeletonLoader.type';
import { containerCSS, loaderCSS } from './SkeletonLoader.style';

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  customCSS,
  centered,
  absoluteHeight,
  absoluteWidth,
  width,
  minWidth,
  maxWidth,
  marginTop = 0,
  marginBottom = 0,
}) => {
  const getWidth = (min = 10, max = 100, w?: number): number => {
    if (!w) {
      const difference = max - min;
      let rand = Math.random();
      rand = Math.floor(rand * difference);
      rand = rand + min;
      return rand;
    }

    return w;
  };

  return (
    <div css={[containerCSS(marginTop, marginBottom), customCSS]}>
      <div
        data-testid="skeleton-loader"
        css={loaderCSS(absoluteHeight, getWidth(minWidth, maxWidth, width), absoluteWidth, centered)}
      />
    </div>
  );
};

export default SkeletonLoader;
