import type { HTTPResponse } from '../../types/api.type';

import type { RelatedPerson } from '../../types/related-person.type';
import { skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';

function useRelatedPeopleQuery({ personGid }: { personGid?: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.relatedPeople, personGid],
    queryFn: personGid
      ? async () =>
        await api.get<HTTPResponse<{ related_people: RelatedPerson[] }>>(
          API_PATH.relatedPeople.replace(':gid', personGid),
        )
      : skipToken,
    select: ({ data }) => data.related_people,
  });
}

export default useRelatedPeopleQuery;
