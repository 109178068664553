import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { activeElementResetCSS } from '../../../shared/shared.style.ts';
import THEME from '../../../styles/theme.ts';

export const containerCSS = css`
  padding: 16px;
`;

export const headerCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`;

export const closeButtonCSS = css`
  ${activeElementResetCSS};
`;

export const logoCSS = css`
  height: 30px;
  margin-left: 24px;
`;

export const navigationCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const navItemCSS = css`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
`;

export const navLinkCSS = css`
  ${navItemCSS};
  ${activeElementResetCSS};
`;

export function navLinkInnerCSS(isActive: boolean): SerializedStyles {
  return css`
    display: inline-flex;
    align-items: center;
    position: relative;
    ${isActive
    && css`
      color: ${THEME.color.azure50};
      &:before {
        content: '';
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: -8px;
        right: -8px;
        width: auto;
        border-radius: ${THEME.size.borderRadius}px;
        background-color: ${THEME.color.azure95};
        z-index: -1;
      }
    `}
`;
}

export const navIconCSS = css`
  margin-right: 12px;
`;

export const contactNavItemCSS = css`
  ${navItemCSS};
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const contactLinkCSS = css`
  ${activeElementResetCSS};
  display: block;
  margin-bottom: 16px;
  margin-left: 52px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`;
