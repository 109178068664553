import type { FC } from 'react';

import type { FactorItemProps } from './FactorItem.type';
import LockIcon from '../../assets/svg/lock.svg?react';
import { useDynamicTextReplacements } from '../../hooks/use-dynamic-text-replacements.tsx';
import ReplacedText
  from '../../shared/components/ReplacedText/ReplacedText.tsx';
import Tooltip from '../../shared/components/Tooltip/Tooltip.tsx';
import TooltipQuestionButton from '../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import {
  containerCSS,
  labelWrapperCSS,
  levelCSS,
  levelMarkerCSS,
  levelWrapperCSS,
  lockIconCSS,
  topWrapperCSS,
  valueButtonCSS,
  valueCSS,
} from './FactorItem.style';
import { getFactorLevelName } from './FactorItem.util.ts';

const FactorItem: FC<FactorItemProps> = ({
  customCSS,
  title,
  titleHint,
  valueHint,
  value,
  riskLevel,
  impactLevel,
  highlightValue,
}) => {
  const dynamicTextReplacements = useDynamicTextReplacements();

  const renderValue = () => {
    if (!value) {
      return <LockIcon css={lockIconCSS} />;
    }

    return valueHint
      ? (
          <Tooltip content={valueHint} customCSS={{ buttonCSS: valueButtonCSS }}>
            <span css={valueCSS(highlightValue, riskLevel)}>{value}</span>
          </Tooltip>
        )
      : (
          <span css={valueCSS(highlightValue, riskLevel)}>{value}</span>
        );
  };

  const renderLevelLabel = (): string => {
    let level = '';
    if (riskLevel) {
      level = `${getFactorLevelName(riskLevel)} Risk`;
    }

    if (impactLevel) {
      level = level
        ? `${level} | ${getFactorLevelName(impactLevel)} Impact`
        : `${getFactorLevelName(impactLevel)} Impact`;
    }

    return level || 'Unknown';
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={topWrapperCSS}>
        <div css={labelWrapperCSS}>
          {title}
          {!!titleHint?.length && (
            <TooltipQuestionButton
              size={14}
              content={
                titleHint.map(hint => (
                  <ReplacedText replacements={dynamicTextReplacements} template={hint} key={hint} />
                ))
              }
            />
          )}
        </div>
        <div>{renderValue()}</div>
      </div>
      <div css={levelWrapperCSS}>
        <span css={levelMarkerCSS(riskLevel)} />
        <span css={levelCSS}>{renderLevelLabel()}</span>
      </div>
    </div>
  );
};

export default FactorItem;
