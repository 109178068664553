import type { CalendlyData, InitProps } from './use-init-calendly.type';
import { useCallback, useEffect, useRef, useState } from 'react';
import CALENDLY from '../../constants/calendly';
import noop from '../../shared/utils/noop.ts';
import useAnalytics from '../use-analytics.ts';
import { CALENDLY_ID, filterCalendlyEvents } from './use-init-calendly.util';

function useInitCalendly({
  gid,
  url,
  isPopup = false,
  prefillData,
  onEventScheduled = noop,
}: InitProps): CalendlyData {
  const [step, setStep] = useState(0);
  const isAddedCalendly = useRef(false);

  const { track } = useAnalytics();

  const initCalendlyWidget = useCallback(() => {
    if (isAddedCalendly.current)
      return;

    const parentElement = document.getElementById(CALENDLY_ID) ?? null;

    const calendlyAction = isPopup ? CALENDLY.calendlyInitWidgetPopup : CALENDLY.calendlyInitWidgetInline;

    (window as any).Calendly[calendlyAction]({
      ...(parentElement && { parentElement }),
      url,
      prefill: {
        name: `${prefillData?.firstName ?? ''} ${prefillData?.lastName ?? ''}`,
        email: prefillData?.email ?? '',
        customAnswers: {
          a1: `1${prefillData?.phone}`,
          a2: `1${prefillData?.phone}`,
        },
      },
      utm: {
        utmTerm: gid,
        utmMedium: 'web_app',
        utmSource: 'customer_portal',
      },
    });
    isAddedCalendly.current = true;
  }, [isPopup, gid, prefillData?.email, prefillData?.firstName, prefillData?.lastName, prefillData?.phone, url]);

  const onCalendlyEvent = useCallback(
    (eventName: string) => {
      switch (eventName) {
        case CALENDLY.calendlyEventTypeViewed:
          setStep(1);
          break;
        case CALENDLY.calendlyTimeSelected:
          track('Schedule A Call Time Selected');
          setStep(2);
          break;
        case CALENDLY.calendlyEventScheduled:
          track('Conversation Scheduling Finished');
          onEventScheduled();
          setStep(3);
          break;
        default:
          break;
      }
    },
    [onEventScheduled, track],
  );

  const addCalendlyLinkTag = (): void => {
    const link = document.createElement('link');
    link.href = CALENDLY.calendlyStylesLinkUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  const removeCalendlyLinkTag = (): void => {
    const linkTag = document?.querySelector('link');
    if (linkTag && linkTag.href === CALENDLY.calendlyStylesLinkUrl) {
      document?.head.removeChild(linkTag);
    }
  };

  const addCalendlyScriptTag = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = CALENDLY.calendlyWidgetScriptUrl;
    script.onload = initCalendlyWidget;
    document.head.appendChild(script);
  }, [initCalendlyWidget]);

  const setupCalendly = useCallback(() => {
    const { Calendly } = window as any;

    if (!Calendly) {
      if (isPopup)
        addCalendlyLinkTag();
      addCalendlyScriptTag();
    }
    window.addEventListener('message', filterCalendlyEvents(onCalendlyEvent));
    if (Calendly)
      initCalendlyWidget();
  }, [addCalendlyScriptTag, initCalendlyWidget, isPopup, onCalendlyEvent]);

  const calendlyInit = useCallback(() => {
    if (isAddedCalendly.current || !document)
      return;

    setupCalendly();

    const timer = setTimeout(() => {
      document
        ?.querySelector('iframe')
        ?.setAttribute('aria-label', 'Calendly widget to select date and time for a chat.');
    }, 500);

    return () => clearTimeout(timer);
  }, [setupCalendly]);

  useEffect(() => {
    if (!isPopup) {
      removeCalendlyLinkTag();
    }
  }, [isPopup]);

  return { calendlyInit, step, isLoaded: isAddedCalendly.current };
}

export default useInitCalendly;
