import type { HTTPResponse } from '../../types/api.type';

import type { Proposal } from '../../types/proposal.type';
import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import api from '../api';

function useTrackProposalOpened() {
  return useMutation({
    mutationFn: async (proposalGid: string) =>
      await api
        .put<HTTPResponse<{ proposal: Proposal }>>(API_PATH.trackProposalOpened.replace(':gid', proposalGid))
        .then(async res => res.data),
  });
}

export default useTrackProposalOpened;
