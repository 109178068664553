import type { FC } from 'react';
import type { SpecificQuestionProps } from '../Question.type';
import { QuestionLabelWrapper } from '../../QuestionWrapper/QuestionLabel/QuestionLabel.type';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import RadioInput from './RadioInput/RadioInput';
import { additionalGapCSS } from './RadioQuestion.util';

const RadioQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  hint,
  isDisabled,
  validations,
  variants,
  gridWidth,
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hideLabel={hideLabel}
    hint={hint}
    description={description}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      name: questionKey,
      options:
        variants?.map(v => ({
          label: v.label,
          value: v.value,
          description: v.description,
        })) ?? [],
      isDisabled,
      validations,
    }}
    inputComponent={RadioInput}
    labelTag={QuestionLabelWrapper.Div}
    customCSS={additionalGapCSS}
  />
);
export default RadioQuestion;
