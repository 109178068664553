import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries.ts';
import THEME from '../../styles/theme.ts';

export const tabsCSS = css`
  display: flex;
  align-items: baseline;
  gap: 32px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${THEME.color.gray60};
`;

export const tabWithTagCSS = css`
  display: flex;
  gap: 0 8px;
  flex-wrap: wrap;
  align-items: center;
`;

export const activeTabCSS = css`
  color: ${THEME.color.azure50};
  flex-shrink: 0;
  border-bottom: 2px solid ${THEME.color.azure50};
`;

export const tagCSS = css`
  padding: 4px 12px;
  background-color: ${THEME.color.gray10};
  border-radius: 40px;
  display: inline-flex;
`;

export const cardCustomCSS = css`
  padding: 20px;
  border-radius: 12px;


  margin-top: 34px;
  margin-bottom: 16px;
  
  ${mq[1]} {
    margin-top: 60px;
    margin-bottom: 20px;
  }
`;

export const sectionHeadingCustomCSS = css`
  margin: 0;

  p {
    margin: 20px 0;
  };
    
  ${mq[1]} {
    margin: 0;
  };
`;

export const factorHeadingContainerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
`;

export const factorHeadingTextCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  font-size: 24px;
  font-weight: 700;
  flex-wrap: wrap;
  gap: 4px;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${THEME.color.gray60};

    button {
      margin-left: 4px;
    }
  }
`;

export const customEditButtonCSS = css`
  font-size: 14px;
  color: ${THEME.color.black};
  font-weight: 700;
  gap: 4px;
`;

export const dividerCSS = css`
  border-color: ${THEME.color.gray10};
  margin-top: 20px;
`;

export const infoMessageCSS = css`
  display: flex;
  gap: 20px;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;

  button {
    flex-shrink: 0;
    font-size: 14px;
  }

  span {
    width: 100%;
    line-height: 20px;
  }

  ${mq[1]} {
    flex-direction: row;
  };
`;
