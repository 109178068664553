import type { MarketingUrlParams } from '../types/route-params.type.ts';
import { useSearchParams } from 'react-router';

export function useMarketingUrlParams(): MarketingUrlParams {
  const [searchParams] = useSearchParams();
  return {
    utm_campaign: searchParams.get('utm_campaign') || undefined,
    utm_source: searchParams.get('utm_source') || undefined,
    utm_medium: searchParams.get('utm_medium') || undefined,
    utm_term: searchParams.get('utm_term') || undefined,
    utm_content: searchParams.get('utm_content') || undefined,
    sd_amm: searchParams.get('sd_amm') || undefined,
    sd_mo: searchParams.get('sd_mo') || undefined,
  };
}
