import type { FC } from 'react';
import type { InfoRequestTooltipProps } from './InfoRequestTooltip.type.ts';
import useAnalytics from '../../hooks/use-analytics.ts';
import useDpNavigate from '../../hooks/use-dp-navigate';
import Button from '../../shared/components/Button/Button';
import { AnalyticLocation } from '../../types/analytics.type.ts';
import { DpCta } from '../../types/digital-profile.type';
import TooltipFrame from '../TooltipFrame/TooltipFrame.tsx';

const InfoRequestTooltip: FC<InfoRequestTooltipProps> = ({ customCSS, page }) => {
  const navigateToDp = useDpNavigate();
  const { track } = useAnalytics();

  const handleUnlockInfo = () => {
    track('Unlock Portal Data Requested', {
      page,
      location: AnalyticLocation.Chart,
    });
    navigateToDp(DpCta.Quotes, page);
  };

  return (
    <TooltipFrame
      customCSS={customCSS}
      title="We need more Info"
      description="Matic needs more information about your home before we can show you this graph"
    >
      <Button fullWidth onClick={handleUnlockInfo}>
        Unlock this info
      </Button>
    </TooltipFrame>
  );
};

export default InfoRequestTooltip;
