import { FullStory, isInitialized } from '@fullstory/browser';
import { useEffect, useState } from 'react';
import useCreateFullStorySession from '../api/full-story-sessions/use-create-full-story-session';

function useTrackFullStorySession({
  personGid,
  engagementGid,
  proposalGid,
  isOpenedFromAmp,
}: {
  personGid?: string;
  engagementGid?: string;
  proposalGid?: string;
  isOpenedFromAmp?: boolean;
}) {
  const { mutate: createFullStorySession } = useCreateFullStorySession();
  const isFSInitialized = isInitialized();
  const [fullStorySessionUrl, setFullStorySessionUrl] = useState<string | null>(null);

  useEffect(() => {
    const getSessionUrl = async () => {
      try {
        const sessionUrl = await FullStory('getSessionAsync');
        setFullStorySessionUrl(sessionUrl);
      }
      catch (error) {
        console.error('Failed to get FullStory session URL', error);
      }
    };
    if (isFSInitialized) {
      getSessionUrl();
    }
  }, [isFSInitialized]);

  useEffect(() => {
    if (!isOpenedFromAmp && personGid && engagementGid && proposalGid && fullStorySessionUrl) {
      createFullStorySession({ personGid, engagementGid, proposalGid, sessionUrl: fullStorySessionUrl });
    }
  }, [personGid, engagementGid, proposalGid, isOpenedFromAmp, createFullStorySession, fullStorySessionUrl]);
}

export default useTrackFullStorySession;
