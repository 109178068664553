import type { AccountResponse } from '../../types/account.type.ts';

import type { HTTPResponse } from '../../types/api.type.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import api from '../api.ts';

// TODO: How we can separate concept of auth session and track session?
// it look like we need to have a different names for this concepts
function useAuthenticate() {
  const queryClient = useQueryClient();
  return useMutation({
    throwOnError: false,
    mutationFn: async (data: {
      token: string;
      gid?: string | null;
      person_gid?: string | null;
      engagement_gid?: string | null;
      session_gid?: string | null;
    }) =>
      await api.post<HTTPResponse<AccountResponse>>(API_PATH.sessions, {
        body: { data },
      }).then(async (res) => {
        await queryClient.refetchQueries({
          queryKey: [QUERY_CACHE_KEYS.account, res.data.account.gid],
        });
        return res.data;
      }),
  });
}
export default useAuthenticate;
