import type { HTTPResponse } from '../../types/api.type.ts';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import API_PATH from '../../constants/api.ts';
import ROUTES from '../../constants/routes.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import { isAuthorizationError } from '../../utils/error.util.ts';
import api from '../api.ts';

function useMutateCustomerChoice(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);
  const navigate = useNavigate();

  return useMutation({
    throwOnError: false,
    mutationFn: async (quoteGid: string) =>
      await api
        .post<HTTPResponse<Record<never, never>>>(API_PATH.customerChoice
          .replace(':gid', gid)
          .replace(':quote_gid', quoteGid))
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        })
        .catch((error) => {
          if (isAuthorizationError(error)) {
            navigate(ROUTES.signIn);
          }
          else {
            throw error;
          }
        }),
  });
}

export default useMutateCustomerChoice;
