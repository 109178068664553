import type { ZodIssue, ZodType } from 'zod';
import * as Sentry from '@sentry/react';
import { isAxiosError } from 'axios';

type ErrorMessage =
  'Failed to send magic link' |
  'Failed to update account data' |
  'Failed to select a quote' |
  'Quote GID is missing' |
  string;

export function reportAPIExecutionError(message: ErrorMessage, error?: unknown): void {
  Sentry.captureMessage(message, {
    level: 'error',
    contexts: {
      response: isAxiosError(error)
        ? {
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data,
          }
        : undefined,
    },
  });
}

export function reportErrorMessage(message: ErrorMessage): void {
  Sentry.captureMessage(message, { level: 'error' });
}

const UNAUTHORIZED_HTTP_STATUS = 401;
const FORBIDDEN_HTTP_STATUS = 403;
const ERRORS_SKIP_REPORT = [UNAUTHORIZED_HTTP_STATUS, FORBIDDEN_HTTP_STATUS];
export const ERRORS_AUTHORIZATION = [UNAUTHORIZED_HTTP_STATUS, FORBIDDEN_HTTP_STATUS];

export function isErrorToSkip(error: unknown): boolean {
  return isAxiosError(error) && ERRORS_SKIP_REPORT.includes(error.response?.status ?? 0);
}

export function isAuthorizationError(error: unknown): boolean {
  return isAxiosError(error) && ERRORS_AUTHORIZATION.includes(error.response?.status ?? 0);
}

export function captureZodIssue(issue: ZodIssue) {
  const message = `Zod Validation Error: ${issue.path[0]}
    - Path: ${issue.path.join('.')}
    - Message: ${issue.message}
    - Code: ${issue.code}
  `;

  Sentry.captureMessage(message);
}

export function checkResponseType<T>(data: T, schema: ZodType): T {
  const parsedDataBySchema = schema.safeParse(data);

  if (!parsedDataBySchema.success) {
    // TODO: Uncomment this when we fix schema issues
    // parsedDataBySchema.error.issues.map((issue: ZodIssue) => captureZodIssue(issue));
  }
  return data;
}
