import type { FC } from 'react';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import type { PolicyInfo } from '../../types/score.type.ts';
import type { ScoreProfileModalProps } from './ScoreProfileModal.type.ts';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import useMutatePolicyInfo from '../../api/score/use-mutate-policy-info.ts';
import useAnalytics from '../../hooks/use-analytics.ts';
import LoadingDots from '../../shared/components/LoadingDots/LoadingDots.tsx';
import Modal from '../../shared/components/Modal/Modal';
import Notification from '../../shared/components/Notification/Notification.tsx';
import TooltipQuestionButton from '../../shared/components/TooltipQuestionButton/TooltipQuestionButton';
import { ScoreType } from '../../types/score.type.ts';
import { reportAPIExecutionError } from '../../utils/error.util.ts';
import { QuestionType } from '../Question/Question.type.ts';
import RadioQuestion from '../Question/RadioQuestion/RadioQuestion';
import TextInputQuestion from '../Question/TextInputQuestion/TextInputQuestion';
import {
  DEFAULT_SCORE_TEXT_INPUT_VALIDATION,
  DWELLING_COV_TOOLTIP,
  LIABILITY_TOOLTIP,
  MODAL_ACTION_BUTTON,
  MODAL_FORM_PROPS,
  MODAL_LOADING_PROPS,
  PROPERTY_LIMIT_INPUT_VALIDATION,
  PROPERTY_LIMIT_TOOLTIP,
} from './ScoreProfileModal.util';

const ScoreProfileModal: FC<ScoreProfileModalProps> = ({ isOpen, data, location, onClose }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const [notification, setNotification] = useState<string | null>(null);
  const { mutateAsync: updateScore, isPending: isScoreUpdatePending } = useMutatePolicyInfo(gid, ScoreType.Protection);
  const isReset = useRef(false);
  const { track } = useAnalytics();

  const methods = useForm<PolicyInfo>({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: 'onTouched',
  });

  useEffect(() => {
    if (Object.keys(data).length > 0 && !isReset.current) {
      methods.reset({
        ...data,
      });

      isReset.current = true;
    }
  }, [methods, data]);

  const onFormSubmit = async (data: PolicyInfo) => {
    if (isScoreUpdatePending) {
      return;
    }

    try {
      await updateScore(data);
      track('Score Profile Updated', { location });
      setNotification('Your protection factors have been updated');
      onClose();
    }
    catch (error) {
      reportAPIExecutionError('Failed to protection factors data', error);
      setNotification('Something went wrong. Please try again later.');
      onClose();
    }
  };

  const values = methods.watch();

  const getModalProps = () => {
    if (isScoreUpdatePending) {
      return MODAL_LOADING_PROPS;
    }
    const isFormNotEmpty = Object.values(values).some(value => !!value);
    const modalButtonProps = { ...MODAL_ACTION_BUTTON, disabled: !isFormNotEmpty || !methods.formState.isDirty };

    return {
      ...MODAL_FORM_PROPS,
      actionButtons: [modalButtonProps],
      onClose,
    };
  };

  return (
    <>
      <Notification
        open={!!notification}
        message={notification || ''}
        onClose={() => setNotification(null)}
      />

      <Modal isOpen={isOpen} {...getModalProps()}>
        <FormProvider {...methods}>
          {isScoreUpdatePending
            ? (
                <>
                  <LoadingDots />
                  <h3>Updating protection factors...</h3>
                  <p>This might take up to 30 seconds</p>
                </>
              )
            : (
                <form onSubmit={methods.handleSubmit(onFormSubmit)} id="score-profile-form">
                  <TextInputQuestion
                    questionKey="dwelling_coverage_limit"
                    label={(
                      <>
                        Dwelling coverage limit
                        <TooltipQuestionButton size={16} content={DWELLING_COV_TOOLTIP} />
                      </>
                    )}
                    dense
                    validations={DEFAULT_SCORE_TEXT_INPUT_VALIDATION}
                    trackingForbidden
                    type={QuestionType.Number}
                  />
                  <TextInputQuestion
                    questionKey="personal_property_limit"
                    label={(
                      <>
                        Personal property limit
                        <TooltipQuestionButton size={16} content={PROPERTY_LIMIT_TOOLTIP} />
                      </>
                    )}
                    dense
                    validations={PROPERTY_LIMIT_INPUT_VALIDATION}
                    trackingForbidden
                    type={QuestionType.Number}
                  />
                  <TextInputQuestion
                    questionKey="liability_coverage_limit"
                    label={(
                      <>
                        Liability coverage limit
                        <TooltipQuestionButton size={16} content={LIABILITY_TOOLTIP} />
                      </>
                    )}
                    dense
                    validations={DEFAULT_SCORE_TEXT_INPUT_VALIDATION}
                    trackingForbidden
                    type={QuestionType.Number}
                  />
                  <RadioQuestion
                    label="Do you have wind/hail coverage?"
                    questionKey="wind_hail_coverage_presence"
                    variants={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                  <RadioQuestion
                    label="Do you have a flood insurance policy?"
                    questionKey="flood_insurance_policy_presence"
                    variants={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                </form>
              )}
        </FormProvider>
      </Modal>
    </>

  );
};

export default ScoreProfileModal;
