import type { FC } from 'react';
import type { SignUpFormFooterProps } from './SignUpFormFooter.type.ts';
import { useNavigate } from 'react-router';
import useDisclosuresQuery from '../../../api/disclosures/use-disclosures-query.ts';
import ROUTES from '../../../constants/routes.ts';
import { useDynamicTextReplacements } from '../../../hooks/use-dynamic-text-replacements.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import ReplacedText from '../../../shared/components/ReplacedText/ReplacedText.tsx';
import { customButtonCSS, paragraphCSS } from './SignUpFormFooter.style.ts';

const SignUpFormFooter: FC<SignUpFormFooterProps> = ({ customCSS }) => {
  const dynamicTextReplacements = useDynamicTextReplacements('(833) 382-1298');
  const { data: disclosuresData } = useDisclosuresQuery('sign-up');
  const navigate = useNavigate();

  return (
    <div css={customCSS}>
      {disclosuresData?.disclosures.map(disclosure => (
        <ReplacedText
          customCSS={paragraphCSS}
          replacements={{ ...dynamicTextReplacements, cta_text: 'Sign Up' }}
          template={disclosure.content}
          key={disclosure.content}
        />
      ))}

      <Button variant="text" onClick={() => navigate(ROUTES.signIn)} customCSS={customButtonCSS}>
        Already have an account? Log in Here.
      </Button>
    </div>
  );
};

export default SignUpFormFooter;
