import type { ReactElement } from 'react';
import type { AddressSuggestion } from '../../../types/smarty-streets.type.ts';
import ChevronIcon from '../../../assets/svg/chevron.svg';
import { caretCSS, entriesCSS } from './AddressOption.style.ts';

export function getOptionString({
  street_line: streetLine,
  secondary,
  city,
  state,
  zipcode,
  entries,
}: AddressSuggestion): string {
  const secondaryStr = secondary ? ` ${secondary}` : '';
  const entriesStr = +entries > 1 ? ` (${entries})` : '';

  return `${streetLine}${secondaryStr}${entriesStr} ${city}, ${state} ${zipcode}`;
}

export function renderOptionLabel(inputValue = '', optionString: string, entries: number): ReactElement {
  const inputLow = inputValue.toLowerCase().trim();
  const optionLow = optionString.toLowerCase().trim();
  let i = 0;

  for (; i < optionString.length; i++) {
    if (inputLow[i] === undefined || inputLow[i] !== optionLow[i])
      break;
  }

  const common = optionString.slice(0, i);
  const suggested: Array<string | ReactElement> = optionString.slice(i).split(`(${entries})`);

  if (entries > 1) {
    suggested.splice(
      1,
      0,
      <span css={entriesCSS} key="entries">
        ({entries} entries)
      </span>,
    );
  }

  return (
    <>
      <strong data-testid="address-bold">{common}</strong>
      {suggested.map(sub => sub)}
      {entries > 1 && (
        <svg viewBox="0 0 24 24" xmlnsXlink="http://www.w3.org/1999/xlink" css={caretCSS}>
          <use xlinkHref={`${ChevronIcon}#right`} x="0" y="24" />
        </svg>
      )}
    </>
  );
}
