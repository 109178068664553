import type { HTTPResponse } from '../../types/api.type';
import type { Savings } from '../../types/savings.type';

import { useQueries } from '@tanstack/react-query';
import queryString from 'query-string';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';

async function getSaving(personGid: string, quoteGid: string) {
  return await api.get<HTTPResponse<Savings>>(
    `${API_PATH.savings.replace(':person_gid', personGid)}?${queryString.stringify({ quote_gid: quoteGid })}`,
  );
}

function useSavingsQueries({ personGid, quotesGids }: { personGid: string; quotesGids: string[] }) {
  const queries = useQueries({
    queries: quotesGids.map(quoteGid => ({
      queryKey: [QUERY_CACHE_KEYS.savings, personGid, quoteGid],
      queryFn: async () => getSaving(personGid, quoteGid),
    })),
  });
  return { queries, totalSavings: queries.reduce((acc, query) => acc + (query.data?.data.savings || 0), 0) };
}

export default useSavingsQueries;
