import { css } from '@emotion/react';
import THEME from '../../styles/theme';

export const fakeTooltipCSS = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px var(--side-padding, 41px);
  background-color: ${THEME.color.white};
  border: 1px solid ${THEME.color.gray30};
  width: 360px;
  box-sizing: border-box;
  border-radius: 4px;
  filter: drop-shadow(4px 4px 0px #0000001F);

  :after,
  :before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: 100%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
  }

  :after {
    border-color: ${THEME.color.white} transparent transparent transparent;
    border-width: 11px;
    top: calc(100% - 1px)
  }

  :before {
    border-color: ${THEME.color.gray30} transparent transparent transparent;
    border-width: 11px;
  }
`;

export const titleCSS = css`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;

export const descriptionCSS = css`
  margin-bottom: 18px;
  width: calc(100% + var(--side-padding, 41px));
  position: relative;
  left: calc(-1 * var(--side-padding, 41px) / 2);
  text-align: center;
`;
