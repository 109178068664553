import { z } from 'zod';
import { FactorLevel, ScoreType } from '../../types/score.type';
import { PolicyInfoSchema } from './score-query.schema';

export const FactorSchema = z.object({
  key: z.string(),
  title: z.string(),
  risk: z.union([z.nativeEnum(FactorLevel), z.literal(''), z.null()]).optional(), // TODO: remove empty string, should be FactorLevel.Unknown
  score: z.number().nullable().optional(),
  tooltip: z.array(z.string()).optional(),
  explanation: z.string().optional(),
  impact_level: z.nativeEnum(FactorLevel).nullable().optional(),
  display_value: z.string().optional(),
});

export const ScoreSchema = z.object({
  key: z.nativeEnum(ScoreType),
  value: z.number().nullish(),
  max_value: z.number().nullish(),
  factors: z.array(FactorSchema),
});

export const ScoreResponseSchema = z.object({
  score: ScoreSchema,
  policy_info: PolicyInfoSchema,
});
