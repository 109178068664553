import type { StepItemProps } from './StepItem/StepItem.type';
import discountDiamond from '../../../assets/svg/dp-start/diamond.svg';
import myHome from '../../../assets/svg/dp-start/home.svg';
import profile from '../../../assets/svg/dp-start/profile.svg';

export const INTRO_STEPS_CONTENT: Omit<StepItemProps, 'count'>[] = [
  {
    img: {
      src: discountDiamond,
      alt: 'Diamond',
    },
    description: 'Discounts',
  },
  {
    img: {
      src: myHome,
      alt: 'Home',
    },
    description: 'My Home',
  },
  {
    img: {
      src: profile,
      alt: 'Profile',
    },
    description: 'My Info',
  },
];
