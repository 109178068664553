import type { FC } from 'react';
import type { DefaultRouteParams } from '../../types/route-params.type';
import { NavLink, useParams } from 'react-router';
import MaticLogoIcon from '../../assets/svg/matic-logo.svg?react';
import ROUTES from '../../constants/routes';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import LiveHelpButton from '../LiveHelpButton/LiveHelpButton';
import { containerCSS, logoCSS } from './DpHeader.style';

const DpHeader: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;

  return (
    <div css={containerCSS}>
      <NavLink to={ROUTES.insights.replace(':gid', gid)}>
        <MaticLogoIcon css={logoCSS} />
      </NavLink>

      <LiveHelpButton pageKey={AnalyticPageKey.DigitalProfileIntro} />
    </div>
  );
};

export default DpHeader;
