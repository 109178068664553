import type { FC } from 'react';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import { Navigate, useOutlet, useParams } from 'react-router';
import ROUTES from '../../constants/routes.ts';

const SessionRootInner: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const outlet = useOutlet();

  return outlet || <Navigate to={ROUTES.insights.replace(':gid', gid)} />;
};

export default SessionRootInner;
