import type { FC } from 'react';
import type { DefaultRouteParams } from '../../../types/route-params.type';
import { Link, useParams } from 'react-router';
import ROUTES from '../../../constants/routes';

const FooterCopy: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;

  return (
    <>
      <p>
        Suggested replacement cost range is estimated based on your quoted Replacement Cost coverage
        (aka Dwelling coverage) and the average percent increase of Replacement Cost coverage in your state.
        Actual replacement cost can vary, is determined by many factors, and is subject to change. As a
        homeowner you are responsible for evaluating your own coverage needs to ensure adequate coverage
        in the event of a loss.
      </p>
      <p>
        Each consumer is responsible for evaluating their own coverage needs and obtaining a policy
        that meets those needs.
      </p>
      <p>
        Quotes are estimates only. Quotes are subject to change without notice. Your actual rate, payment
        and coverage may be different. Quotes do not constitute an offer of insurance, nor is any contract,
        agreement, or insurance coverage implied, formed or bound by the provision of quotes. Insurability,
        final insurance premium quotes and an offer of insurance, if any, will be determined by the insurance
        company providing your insurance policy.
      </p>
      <p>Matic Insurance Services Inc, CA License No 0192444</p>
      <Link to={ROUTES.disclaimers.replace(':gid', gid)}>Read all disclaimers.</Link>
    </>
  );
};

export default FooterCopy;
