import type { z } from 'zod';
import type {
  CollectHistoryDataSchema,
  PremiumHistoryItemSchema,
  PremiumHistoryResponseSchema,
  QuotesOverTimeHistoryItemSchema,
  QuotesOverTimeHistoryResponseSchema,
  ReplacementCostHistoryItemSchema,
  ReplacementCostHistoryResponseSchema,
} from '../api/history/history.schema';

export enum HistoryDataStatuses {
  Ready = 'ready',
  Pending = 'pending',
  Unknown = 'unknown',
}

export interface HistoryData<T = PremiumHistoryItem> {
  [key: string]: T;
}

export interface DataItem { year: string }

export interface TransformedHistoryDataItem {
  year: string;
  values: number[];
}

export type TransformedHistoryData = TransformedHistoryDataItem[];

export interface TransformedReplacementCostDataItem {
  year: string;
  values: [ { min: number; max: number }, number];
}

export type TransformedReplacementCostData = TransformedReplacementCostDataItem[];

export type PremiumHistoryResponse = z.infer<typeof PremiumHistoryResponseSchema>;
export type ReplacementCostHistoryResponse = z.infer<typeof ReplacementCostHistoryResponseSchema>;
export type QuotesOverTimeHistoryResponse = z.infer<typeof QuotesOverTimeHistoryResponseSchema>;

export type PremiumHistoryItem = z.infer<typeof PremiumHistoryItemSchema>;
export type ReplacementCostHistoryItem = z.infer<typeof ReplacementCostHistoryItemSchema>;
export type QuotesOverTimeHistoryItem = z.infer<typeof QuotesOverTimeHistoryItemSchema>;

export type CollectHistoryData = z.infer<typeof CollectHistoryDataSchema>;
