import type { DeepPartial } from 'react-hook-form';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { Policy, RecentPolicyResponse } from '../../types/policy.type.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import api from '../api.ts';

function useMutatePersonPolicy(person_gid: string, policy_gid: string) {
  const { gid } = useParams() as DefaultRouteParams;
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: DeepPartial<Policy>) =>
      await api
        .patch<HTTPResponse<RecentPolicyResponse>>(
          API_PATH.updatePersonPolicy.replace(':person_gid', person_gid).replace(':policy_gid', policy_gid),
          {
            body: { data },
          },
        )
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }

          await queryClient.setQueryData(
            [QUERY_CACHE_KEYS.recentPolicy, gid],
            { ...res.data },
          );

          return res.data;
        }),
    onSuccess: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.premiumHistory], type: 'active' }),
      queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.replacementCostHistory], type: 'active' }),
    ]),
  });
}

export default useMutatePersonPolicy;
