import type { FC } from 'react';
import type { AddressOptionProps } from './AddressOption.type.ts';
import { useEffect, useRef } from 'react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../constants/full-story';
import { addressOptionCSS } from './AddressOption.style';
import { getOptionString, renderOptionLabel } from './AddressOption.util.tsx';

const AddressOption: FC<AddressOptionProps> = ({
  inputValue = '',
  option,
  index,
  trackForbidden,
  isFocused,
  onHover,
  onSelect,
  isSelected = false,
}) => {
  const optionString = getOptionString(option);
  const optionEl = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isFocused && optionEl.current) {
      optionEl.current.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    }
  }, [isFocused]);

  return (
    <button
      type="button"
      ref={optionEl}
      css={addressOptionCSS(isFocused, isSelected)}
      onClick={() => onSelect(option, optionString)}
      onMouseEnter={() => onHover(index)}
      title={optionString}
      role="option"
      aria-selected={isSelected}
      className={trackForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
    >
      {renderOptionLabel(inputValue, optionString, +option.entries)}
    </button>
  );
};

export default AddressOption;
