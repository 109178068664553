import type { FC, MouseEventHandler } from 'react';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import type { WelcomeMessageProps } from './WelcomeMessage.type.ts';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import CloseIcon from '../../../assets/svg/close.svg?react';
import PersonWithChatBubbleIcon from '../../../assets/svg/person-with-chat-bubble.svg?react';
import useResponsive from '../../../hooks/use-responsive.ts';
import SVGButton from '../../../shared/components/SVGButton/SVGButton.tsx';
import {
  ANIMATE_HIDE_CLASS,
  animationContainerCSS,
  closeButtonCSS,
  containerCSS,
  imageCSS,
  textColCSS,
} from './WelcomeMessage.style.ts';
import { NEW_ACCOUNT_SEARCH_PARAM, NEW_ACCOUNT_STORAGE_KEY } from './WelcomeMessage.util.ts';

const WelcomeMessage: FC<WelcomeMessageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);

  const { isMobile } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();
  const newAccountParam = searchParams.get(NEW_ACCOUNT_SEARCH_PARAM);
  const isNewAccount = sessionStorage.getItem(NEW_ACCOUNT_STORAGE_KEY);
  const [isShown, setIsShown] = useState(!!isNewAccount);

  useEffect(() => {
    if (newAccountParam && !isNewAccount) {
      sessionStorage.setItem(NEW_ACCOUNT_STORAGE_KEY, newAccountParam);
      searchParams.delete(NEW_ACCOUNT_SEARCH_PARAM);
      setSearchParams(searchParams);
      setIsShown(true);
    }
  }, [newAccountParam, isNewAccount, searchParams, setSearchParams]);

  useLayoutEffect(() => {
    if (!isShown && isNewAccount) {
      sessionStorage.removeItem(NEW_ACCOUNT_STORAGE_KEY);
    }
  }, [isShown, isNewAccount]);

  const onClose: MouseEventHandler<HTMLButtonElement> = (el) => {
    el.currentTarget.blur();
    setIsShown(false);
  };

  if (!isNewAccount) {
    return null;
  }

  return (
    <div
      css={animationContainerCSS}
      data-testid="welcome-message"
      className={!isShown ? ANIMATE_HIDE_CLASS : ''}
      aria-hidden={isShown ? 'false' : 'true'}
    >
      <div css={[containerCSS, customCSS]}>
        <SVGButton customCSS={closeButtonCSS} name="Close welcome message" onClick={onClose}>
          <CloseIcon width={16} height={16} />
        </SVGButton>
        <PersonWithChatBubbleIcon css={imageCSS} width={isMobile ? 200 : 264} />
        <div css={textColCSS}>
          <h3>
            Hi {accountData?.account.first_name}, Welcome to your personalized insurance overview!
          </h3>
          <p>
            This is your hub for smarter insurance management. Access tools to better understand options that may
            improve your protection — including coverage insights, customized recommendations, and automatic
            rate monitoring. Start by reviewing your risk and protection scores below.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
