import type { FC } from 'react';
import type { QuoteItemProps } from './QuoteItem.type.ts';
import { useRef } from 'react';
import LockIcon from '../../../assets/svg/lock.svg?react';
import PhoneIcon from '../../../assets/svg/phone.svg?react';
import { FS_ELEMENTS } from '../../../constants/full-story.ts';
import { TEST_ID } from '../../../constants/test-id.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import LoadingDots from '../../../shared/components/LoadingDots/LoadingDots.tsx';
import noop from '../../../shared/utils/noop.ts';
import { reportErrorMessage } from '../../../utils/error.util.ts';
import formatUtil from '../../../utils/format.util.ts';
import {
  bundleTagCSS,
  customLoadingDotsCSS,
  failedCSS,
  loadingCSS,
  loadingMessageCSS,
  logoCSS,
  manualCSS,
  quoteInnerCSS,
  quoteWrapperCSS,
  readyButtonCSS,
  readyCSS,
  readyMessageCSS,
  readyMessageWrapperCSS,
  statusDotCSS,
  unlockButtonCSS,
} from './QuoteItem.style.ts';
import { QuoteStatus } from './QuoteItem.type.ts';

const QuoteItem: FC<QuoteItemProps> = ({ carrier, quote, isUpdating, onQuoteUnlock, onQuoteChoose = noop }) => {
  const innerContainerRef = useRef<HTMLDivElement | null>(null);

  const handleQuoteSelect = () => {
    if (quote?.gid) {
      onQuoteChoose(quote.gid);
    }
    else {
      reportErrorMessage('Quote GID is missing');
    }
  };

  const getContentByState = () => {
    switch (quote?.status) {
      case QuoteStatus.Unknown:
        return (
          <Button
            variant="secondary"
            leftIcon={<LockIcon />}
            customCSS={unlockButtonCSS}
            onClick={onQuoteUnlock}
          >
            Unlock Quote
          </Button>
        );
      case QuoteStatus.Pending:
        return (
          <div css={loadingCSS}>
            <div css={loadingMessageCSS}>
              <div css={statusDotCSS(quote.status)} />
              Fetching Quote
            </div>
            <LoadingDots steps={[0, 0.15, 0.3]} customCSS={customLoadingDotsCSS} />
          </div>
        );
      case QuoteStatus.Failed:
        return (
          <div css={failedCSS}>
            <div css={statusDotCSS(quote.status)} />
            {carrier.supported ? 'Declined to quote' : 'Not available in your location'}
          </div>
        );
      case QuoteStatus.Manual:
        return (
          <div css={manualCSS}>
            <PhoneIcon />
            <div>
              This carrier does not display quotes online. Schedule a call to get this quote.
            </div>
          </div>
        );
      case QuoteStatus.Ready:
        return (
          <div css={readyCSS}>
            <div css={readyMessageWrapperCSS}>
              <div css={readyMessageCSS}>
                <div css={statusDotCSS(quote.status)} />
                <span data-testid={TEST_ID.QUOTE_PREMIUM}>
                  {formatUtil.money(quote.premium || 0, { prefix: '$', suffix: '/yr' })}
                </span>
              </div>
              {quote.showBundleTag && <div css={bundleTagCSS}>Bundle Discount</div>}
            </div>
            <Button
              data-fs-element={FS_ELEMENTS.selectQuoteButton}
              customCSS={readyButtonCSS}
              isLoading={isUpdating}
              onClick={handleQuoteSelect}
            >
              Choose Quote
            </Button>
          </div>
        );
      default:
        return (
          <div css={failedCSS}>
            <div css={statusDotCSS(QuoteStatus.Failed)} />
            Something went wrong
          </div>
        );
    }
  };

  return (
    <div css={quoteWrapperCSS}>
      <div css={quoteInnerCSS} ref={innerContainerRef}>
        <img css={logoCSS} src={carrier.logo_url!} alt={`${carrier.name} logo`} />
        {getContentByState()}
      </div>
    </div>
  );
};

export default QuoteItem;
