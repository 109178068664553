import type { FC } from 'react';

import type { IconButtonProps } from './IconButton.type';

import { ReactSVG } from 'react-svg';
import { visuallyHiddenCSS } from '../../shared.style';
import Wrapper from '../Wrapper/Wrapper.tsx';
import {
  containerCSS,
  containerSingleColorCSS,
  containerSingleColorInvertedCSS,
  imageCSS,
  imageSingleColorCSS,
  imageSingleColorInvertedCSS,
} from './IconButton.style';

const IconButton: FC<IconButtonProps> = ({
  iconType = 'default',
  src,
  wrapperElement = 'button',
  iconText,
  target,
  customCSS,
  ...props
}) => {
  const singleColor = iconType === 'single-color';
  const singleColorInverted = iconType === 'single-color-inverted';

  const elProps = {
    ...props,
    rel: wrapperElement === 'a' && target === '_blank' ? 'noopener noreferrer' : undefined,
    type: wrapperElement === 'button' ? 'button' : undefined,
    target,
  };

  return (
    <Wrapper
      tag={wrapperElement}
      css={[
        customCSS,
        containerCSS,
        singleColor && containerSingleColorCSS,
        singleColorInverted && containerSingleColorInvertedCSS,
      ]}
      {...elProps}
    >
      <ReactSVG
        src={src}
        css={[imageCSS, singleColor && imageSingleColorCSS, singleColorInverted && imageSingleColorInvertedCSS]}
      />
      {iconText && <span css={visuallyHiddenCSS}>{iconText}</span>}
    </Wrapper>
  );
};

export default IconButton;
