import type { SerializedStyles } from '@emotion/react';

import type { FC, ReactNode, SVGProps } from 'react';

import type { ButtonProps } from '../Button/Button.type';

export interface ModalActionButton extends ButtonProps {
  text: string;
  onClick?: () => void;
}

export enum ButtonDirection {
  Row = 'row',
  Column = 'column',
}

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface ModalProps {
  customCSS?: SerializedStyles;
  children: ReactNode;
  isOpen: boolean;
  title?: ReactNode;
  subtitle?: string;
  size?: ModalSize;
  isFullScreen?: boolean;
  icon?: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  isLoading?: boolean;
  blurBackdrop?: boolean;
  actionButtons?: ModalActionButton[];
  buttonDirection?: ButtonDirection;
  onClose?: () => void;
}
