import type { HTTPResponse } from '../../types/api.type';

import { skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';

function useSoldCustomersCountQuery({ agentGid }: { agentGid?: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.soldCustomersCount, agentGid],
    queryFn: agentGid
      ? async () =>
        await api.get<HTTPResponse<{ sold_customers_count: number }>>(
          API_PATH.soldCustomersCount.replace(':gid', agentGid),
        )
      : skipToken,
    select: ({ data }) => data.sold_customers_count,
  });
}

export default useSoldCustomersCountQuery;
