import type { FC } from 'react';
import type { SocialLinkType } from '../../shared/components/Footer/Footer.type.ts';
import { useSearchParams } from 'react-router';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import ROUTES from '../../constants/routes.ts';
import useAnalytics from '../../hooks/use-analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../types/analytics.type.ts';
import ErrorContent from './ErrorContent/ErrorContent.tsx';

const ErrorLayout: FC<{ hideActionButton?: boolean }> = ({ hideActionButton }) => {
  const [searchParams] = useSearchParams();
  const accountGID = searchParams.get('gid');
  const { data: settingsData } = useSettingsQuery(false);
  const { track } = useAnalytics();
  useTrackPage(AnalyticPageKey.Error);

  const onPhoneClicked = (type: SocialLinkType): void => {
    if (type === 'phone') {
      track('Phone Conversation Initiated', {
        page: AnalyticPageKey.Error,
        location: AnalyticLocation.Footer,
      });
    }
  };

  const onActionClick = () => {
    document.location.href = accountGID ? ROUTES.insights.replace(':gid', accountGID) : ROUTES.signIn;
  };

  return (
    <ErrorContent
      button={hideActionButton ? null : { label: 'Back to homepage', onClick: onActionClick }}
      footer={{
        phoneNumber: settingsData?.phone_number || '',
        onSocialLinkClick: onPhoneClicked,
      }}
    />
  );
};

export default ErrorLayout;
