import type { FC } from 'react';
import type { DefaultRouteParams } from '../../../types/route-params.type';
import { Link, useParams } from 'react-router';
import ROUTES from '../../../constants/routes';

const FooterCopy: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;

  return (
    <>
      <p>
        Quotes are estimates only. Quotes are subject to change without notice. Your actual rate,
        payment and coverage may be different. Quotes do not constitute an offer of insurance, nor
        is any contract, agreement, or insurance coverage implied, formed or bound by the provision
        of quotes. Insurability, final insurance premium quotes and an offer of insurance, if any,
        will be determined by the insurance company providing your insurance policy.
      </p>

      <p>Matic Insurance Services Inc, CA License No 0192444</p>
      <Link to={ROUTES.disclaimers.replace(':gid', gid)}>Read all disclaimers.</Link>
    </>
  );
};

export default FooterCopy;
