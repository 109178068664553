import type { FC } from 'react';
import type { CollectChartDataProps, PolicyData } from './CollectChartData.type';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useCreatePersonPolicy from '../../api/person-policies/create-person-policy';
import useMutatePersonPolicy from '../../api/person-policies/mutate-person-policy';
import useAnalytics from '../../hooks/use-analytics';
import Button from '../../shared/components/Button/Button';
import LoadingDots from '../../shared/components/LoadingDots/LoadingDots';
import { CoverageKey } from '../../types/quote.type';
import { reportAPIExecutionError } from '../../utils/error.util';
import Question from '../Question/Question';
import TooltipFrame from '../TooltipFrame/TooltipFrame';
import { CHART_FORM_SCHEMA } from './CollectCharData.util';
import { formInnerCSS, frameWrapperCustomCSS } from './CollectChartData.style';

const CollectChartData: FC<CollectChartDataProps> = ({
  page,
  policyData,
  editableFields,
  personGid,
  policyGid,
  onClose,
  assetGid,
}) => {
  const {
    mutateAsync: updatePersonPolicy,
    isPending: isPersonPolicyPending,
  } = useMutatePersonPolicy(personGid, policyGid!);

  const {
    mutateAsync: createPersonPolicy,
    isPending: isCreatePersonPolicyPending,
  } = useCreatePersonPolicy(personGid);

  const { track } = useAnalytics();

  const methods = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: 'onTouched',
  });

  useEffect(() => {
    methods.reset(policyData);
  }, [policyData, methods]);

  const isPolicyUpdating = isPersonPolicyPending || isCreatePersonPolicyPending;

  const onSubmit = async (policyData: Partial<PolicyData>) => {
    if (isPolicyUpdating) {
      return;
    }

    if (policyGid) {
      try {
        await updatePersonPolicy({
          premium: policyData.premium,
          expiration_date: policyData.expiration_date,
          coverages: [{ key: CoverageKey.Dwelling, limit_value: policyData.limit_value }],
        });
        track('Person Policy Data Updated', { page });
        onClose();
      }
      catch (error) {
        reportAPIExecutionError('Failed to update policy data', error);
      }
      return;
    }

    try {
      await createPersonPolicy({
        premium: policyData.premium!,
        expiration_date: policyData.expiration_date!,
        asset_gids: [assetGid],
        ...(policyData.limit_value && {
          coverages: [{ key: CoverageKey.Dwelling, limit_value: policyData.limit_value }],
        }),
      });
      track('Person Policy Created', { page });
      onClose();
    }
    catch (error) {
      reportAPIExecutionError('Failed to create person policy', error);
    }
  };

  return (
    <TooltipFrame
      {...(!isPolicyUpdating && {
        title: 'One last thing',
        description: 'We need your current policy information to display this graph.',
      })}
      customCSS={frameWrapperCustomCSS(isPolicyUpdating)}
    >
      <FormProvider {...methods}>
        {
          isPolicyUpdating
            ? (
                <>
                  <LoadingDots />
                  <p>Updating the graph...</p>
                </>
              )
            : (
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div css={formInnerCSS}>
                    {CHART_FORM_SCHEMA.map((questionProps) => {
                      return (
                        <Question
                          key={questionProps.questionKey}
                          {...questionProps}
                          isDisabled={!editableFields.includes(questionProps.questionKey)}
                        />
                      );
                    })}
                  </div>

                  <Button type="submit" fullWidth>
                    Update
                  </Button>
                </form>
              )
        }
      </FormProvider>
    </TooltipFrame>
  );
};

export default CollectChartData;
