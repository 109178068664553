import type { FC } from 'react';
import type { TransformedReplacementCostDataItem } from '../../../types/history.type.ts';
import type { ReplacementCostGraphProps } from './ReplacementCostGraph.type';
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
import { TEST_ID } from '../../../constants/test-id.ts';
import { useDynamicTextReplacements } from '../../../hooks/use-dynamic-text-replacements.tsx';
import useResponsive from '../../../hooks/use-responsive.ts';
import useSize from '../../../hooks/use-size.ts';
import ReplacedText from
  '../../../shared/components/ReplacedText/ReplacedText.tsx';
import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import { futureMarkCSS, highlightedDataCSS, highlightedDataWrapperCSS, markCSS } from '../../../styles/chart.style.ts';
import THEME from '../../../styles/theme.ts';
import {
  chartFutureDataBorderColor,
  chartFutureDataColor,
  chartMainColors,
  formatSuffix,
  getLatestYearObject,
  setBarAttributes,
} from '../../../utils/chart.util.ts';
import formatUtil from '../../../utils/format.util.ts';
import { roundToThousands } from '../../../utils/round.util.ts';
import { chartCSS, hoverBgCSS, invisibleHoverBgCSS, xTickCSS, yTickCSS } from './ReplacementCostGraph.style';
import {
  CURR_REPLACEMENT_TOOLTIP,
  EST_REPLACEMENT_TOOLTIP,
  SUG_REPLACEMENT_TOOLTIP,
} from './ReplacementCostGraph.utils.ts';

const currentYear = new Date().getFullYear().toString();

const ReplacementCostGraph: FC<ReplacementCostGraphProps> = ({ data, hidePreview = true }) => {
  const svgRef = useRef(null);
  const size = useSize(svgRef);
  const dynamicTextReplacements = useDynamicTextReplacements();
  const { isMobile } = useResponsive();
  const [selectedData, setSelectedData] = useState<TransformedReplacementCostDataItem | undefined>();

  useEffect(() => {
    if (!hidePreview) {
      setSelectedData(getLatestYearObject<TransformedReplacementCostDataItem>(data));
    }
  }, [data, hidePreview]);

  useEffect(() => {
    if (!size?.width)
      return;

    const animationDuration = hidePreview ? 0 : 1000;
    const margin = { top: 20, bottom: 30, left: 50 };
    const width = size.width - margin.left;
    const height = 243 - margin.top - margin.bottom;

    // clear the previous content for rerenders
    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x0 = d3.scaleBand()
      .domain(data.map(d => d.year))
      .range([0, width])
      .padding(0.4);

    const flatValues = data.flatMap(d =>
      d.values.flatMap(val =>
        typeof val === 'number' ? val : [val.min, val.max],
      ),
    );

    const [maxValue] = [
      d3.max(flatValues)!,
    ];

    const y = d3.scaleLinear()
      .domain([0, maxValue || 100000])
      .range([height, 0])
      .nice();

    const yAxis = svg.append('g')
      .call(
        d3.axisLeft(y)
          .tickFormat(d => formatUtil.money(+d, { cutFromK: 1, prefix: '$' }))
          .ticks(5)
          .tickSize(-width)
          .tickPadding(10),
      );

    yAxis.select('.domain').remove();
    yAxis.selectAll('.tick line')
      .attr('class', 'y-tick')
      .attr('stroke', (_, i) => (i === 0 ? THEME.color.gray80 : THEME.color.gray10));

    // initial position of hoverBg on the last bar
    const lastBarX = x0(data[data.length - 1].year)!;

    const hoverBg = !!maxValue && svg.append('rect')
      .attr('class', 'hover-bg')
      .attr('height', height + 11)
      .attr('width', x0.bandwidth())
      .attr('x', lastBarX)
      .attr('y', -13);

    const groups = svg.selectAll('g.layer')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${x0(d.year)},0)`)
      .each(function (d) {
        d3.select(this)
          .append('rect')
          .attr('class', 'invisible-hover-bg')
          .attr('width', x0.bandwidth())
          .attr('height', height)
          .attr('value', d.year)
          .on('mouseenter touchstart', (_) => {
            const newX = x0(d.year)!;

            if (hoverBg) {
              hoverBg.transition()
                .duration(500)
                .attr('x', newX);
            }

            setSelectedData(d);
          });
      });

    const yearPositions = data.map(d => x0(d.year)!);
    const chartWidth = x0.range()[1]; // This gives the end of the chart

    // Function to calculate the width of the bar
    const calculateBarWidth = (year: string) => {
      const currentIndex = yearPositions.indexOf(x0(year)!);
      const nextIndex = currentIndex + 1;

      if (nextIndex < yearPositions.length) {
        return yearPositions[nextIndex] - yearPositions[currentIndex];
      }
      // If no next bar, extend to the end of the chart
      return chartWidth - yearPositions[currentIndex] - 1;
    };

    // Apply the dynamic width calculation
    groups.selectAll('rect.value-bar')
      .data(d => d.values.map((value, i) => ({ key: i, value: value as { min: number; max: number }, year: d.year })))
      .enter()
      .filter(d => d.key === 0)
      .append('rect')
      .attr('class', 'value-bar')
      .attr('y', d => y(d.value.max)) // Initial y-position
      .attr('height', d => y(d.value.min) - y(d.value.max)) // Start with height 0 for animation
      .attr('fill', d => (d.year > currentYear
        ? chartFutureDataColor(`${d.key}`)
        : chartMainColors(`${d.key}`)) as string)
      .attr('stroke', d => (d.year > currentYear ? chartFutureDataBorderColor('0') : 'none') as string)
      .attr('stroke-dasharray', d => (d.year > currentYear ? '4,2' : 'none'))
      .attr('stroke-width', 2)
      .transition()
      .duration(animationDuration)
      .ease(d3.easeCubicOut)
      .attr('width', d => calculateBarWidth(d.year))
      .attr('y', d => y(d.value.max));

    groups.selectAll('rect.bar')
      .data(d => d.values.map((value, i) => ({ key: i, value, year: d.year })))
      .enter()
      .append('rect')
      .filter(d => d.key === 1)
      .call(setBarAttributes, x0, y, height, currentYear, 0.4, animationDuration);

    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x0)
          .tickSize(16)
          .tickPadding(1)
          .tickFormat(year => `${year}${formatSuffix(+year, +currentYear, isMobile)}`),
      );

    xAxis.selectAll('.tick line')
      .attr('class', 'x-tick')
      .attr('transform', 'translate(0, -10)');

    xAxis.select('.domain').remove();
  }, [size?.width, data, hidePreview, isMobile]);

  const formattedSuffix = formatSuffix(selectedData ? +selectedData.year : +currentYear, +currentYear, false);
  const maticSuggestedMinValue = selectedData ? roundToThousands(selectedData.values[0].min) : 0;
  const maticSuggestedMaxValue = selectedData ? roundToThousands(selectedData.values[0].max) : 0;
  const yourSelectedValue = selectedData ? roundToThousands(selectedData.values[1]) : 0;

  const formatParams = { cutFromK: 1, prefix: '$', suffix: formattedSuffix };
  const isNextYear = selectedData && selectedData.year > currentYear;

  return (
    <>
      {!hidePreview && selectedData
      && (
        <div css={highlightedDataWrapperCSS}>
          <div css={highlightedDataCSS}>
            <div data-testid={TEST_ID.GRAPH_LEGEND_LABEL_1}>
              <div css={isNextYear ? futureMarkCSS(true) : markCSS(THEME.color.violet87)} />
              <p>
                Suggested <span>{selectedData.year}</span> coverage
              </p>
            </div>

            <p data-testid={TEST_ID.GRAPH_LEGEND_VALUE_1}>
              {
                maticSuggestedMinValue
                  ? formatUtil.money(maticSuggestedMinValue, { ...formatParams, suffix: '' })
                  : 'N/A'
              }
              -
              {maticSuggestedMaxValue ? formatUtil.money(maticSuggestedMaxValue, formatParams) : 'N/A'}
              {selectedData.year >= currentYear && (
                <TooltipQuestionButton
                  size={16}
                  content={(
                    SUG_REPLACEMENT_TOOLTIP.map(tip => (
                      <ReplacedText
                        replacements={dynamicTextReplacements}
                        template={tip}
                        key={tip}
                      />
                    ))
                  )}
                />
              )}
            </p>
          </div>

          <div css={highlightedDataCSS}>
            <div data-testid={TEST_ID.GRAPH_LEGEND_LABEL_2}>
              <div css={isNextYear ? futureMarkCSS(false) : markCSS(THEME.color.azure50)} />
              <p>
                Your {isNextYear ? 'predicted ' : ''} <span>{selectedData.year}</span> coverage
              </p>
            </div>

            <p data-testid={TEST_ID.GRAPH_LEGEND_VALUE_2}>
              {yourSelectedValue ? formatUtil.money(yourSelectedValue, formatParams) : 'N/A'}
              {selectedData.year >= currentYear && (
                <TooltipQuestionButton
                  size={16}
                  content={(
                    <ReplacedText
                      replacements={dynamicTextReplacements}
                      template={selectedData.year === currentYear ? CURR_REPLACEMENT_TOOLTIP : EST_REPLACEMENT_TOOLTIP}
                    />
                  )}
                />
              )}
            </p>
          </div>
        </div>
      )}

      <svg
        ref={svgRef}
        css={[chartCSS, yTickCSS, xTickCSS, hoverBgCSS, invisibleHoverBgCSS]}
      />
    </>
  );
};

export default ReplacementCostGraph;
