import type { FC } from 'react';

import type { CanceledProposalProps } from './CanceledProposal.type';
import EmailIcon from '../../../assets/svg/email.svg?react';
import MaticLogoIcon from '../../../assets/svg/matic-logo.svg?react';
import PhoneIcon from '../../../assets/svg/phone.svg?react';
import SIRV_IMAGES from '../../../constants/sirv-images.ts';
import Container from '../../../shared/components/Container/Container.tsx';
import { agentContactCSS, agentContactItemCSS, agentInfoCSS, containerCSS, imageCSS } from './CanceledProposal.style';

const CanceledProposal: FC<CanceledProposalProps> = ({ agentContactInfo }) => {
  const whoops = `Oops! That's on Us`;

  const proposalCanceledApology = `We apologize, but this proposal is no longer available. Please reach out to your
                friendly agent to discuss an updated proposal tailored to your current needs.`;

  return (
    <Container fullscreen customCSS={containerCSS}>
      <MaticLogoIcon height={26} />
      <img src={SIRV_IMAGES.dullСat} alt="Dull cat" css={imageCSS} />
      <div css={agentInfoCSS}>
        <b>{whoops}</b>
        <p>{proposalCanceledApology}</p>
        <div css={agentContactCSS}>
          <div css={agentContactItemCSS}>
            <PhoneIcon />
            {agentContactInfo.phone_number}
          </div>
          <div css={agentContactItemCSS}>
            <EmailIcon />
            {agentContactInfo.email}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CanceledProposal;
