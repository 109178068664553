import type { HTTPResponse } from '../../types/api.type';

import type { Proposal } from '../../types/proposal.type';
import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';

function useProposalQuery({ proposalGid }: { proposalGid?: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.proposal, proposalGid],
    queryFn: proposalGid
      ? async () => await api.get<HTTPResponse<{ proposal: Proposal }>>(API_PATH.proposal.replace(':gid', proposalGid))
      : skipToken,
    select: ({ data }) => data.proposal,
    placeholderData: keepPreviousData,
  });
}

export default useProposalQuery;
