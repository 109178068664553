import { matchPath } from 'react-router';
import ROUTES from '../../constants/routes';

const tabsList = [
  ROUTES.rateReview,
  ROUTES.snapshotDetails,
  ROUTES.replacementCost,
  ROUTES.carrierOptions,
];

// Probably there would be better solution with router refactoring
export function getIsSubInsightPage(pathname: string) {
  return !!tabsList.find(route =>
    matchPath(route, pathname),
  );
}
