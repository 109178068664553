import type { FC } from 'react';
import type { InfoPanelProps } from './InfoPanel.type';
import ExclamationMarkCircleIcon from '../../assets/svg/exclamation-mark-circle.svg?react';
import ExclamationMarkTriangleIcon from '../../assets/svg/exclamation-mark-triangle.svg?react';
import { containerCSS, defaultIconCSS, negativeIconCSS, positiveIconCSS, warningIconCSS } from './InfoPanel.style';

const InfoPanel: FC<InfoPanelProps> = ({ customCSS, type, message }) => {
  const getPanelIcon = () => {
    switch (type) {
      case 'positive':
        return <ExclamationMarkCircleIcon css={positiveIconCSS} />;
      case 'warning':
        return <ExclamationMarkTriangleIcon css={warningIconCSS} />;
      case 'negative':
        return <ExclamationMarkTriangleIcon css={negativeIconCSS} />;
      default:
        return <ExclamationMarkCircleIcon css={defaultIconCSS} />;
    }
  };

  return (
    <div css={[containerCSS(type), customCSS]}>
      {getPanelIcon()}
      {message}
    </div>
  );
};

export default InfoPanel;
