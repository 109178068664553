import type { FullStoryUserProperties } from '../types/analytics.type.ts';
import { FullStory, init, isInitialized } from '@fullstory/browser';

const PLATFORM_NAME = 'Customer Portal';

export default {
  init: (orgId: string) => init({ orgId, recordCrossDomainIFrames: true, devMode: false }),
  identify: (uid: string, properties: FullStoryUserProperties) => {
    if (isInitialized()) {
      FullStory('setIdentity', {
        uid,
        properties: { ...properties },
      });
    }
  },
  page: (pageName: string, properties?: Record<string, any>) => {
    if (isInitialized()) {
      FullStory('setProperties', {
        type: 'page',
        properties: { pageName, platform: PLATFORM_NAME, ...properties },
      });
    }
  },
  track: (name: string, properties?: Record<string, any>) => {
    if (isInitialized()) {
      FullStory('trackEvent', {
        name,
        properties: { platform: PLATFORM_NAME, ...properties },
      });
    }
  },
};
