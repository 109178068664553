import type { FC } from 'react';
import type { AddressAnswer } from '../../../types/answer.type';

import type { SpecificQuestionProps } from '../Question.type';
import { Controller } from 'react-hook-form';
import { getValidationRules, hardcodedValidations } from '../../../utils/question-validations.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import Address from './Address';

const AddressQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  placeholder,
  hint,
  icon,
  isDisabled,
  validations,
  gridWidth,
  dense,
  trackingForbidden,
}) => {
  return (
    <Controller
      name={questionKey}
      rules={getValidationRules(validations, { isValidAddress: hardcodedValidations.isValidAddress })}
      render={({ field: { onChange, value, onBlur, ref }, fieldState: { isTouched } }) => {
        const onSelectChange = (v: AddressAnswer | string): void => {
          onChange(v);
        };

        return (
          <QuestionWrapper
            questionKey={questionKey}
            label={label}
            hideLabel={hideLabel}
            hint={hint}
            description={description}
            icon={icon}
            isDisabled={isDisabled}
            gridWidth={gridWidth}
            inputProps={{
              inputId: questionKey,
              errorHeading: label,
              value,
              placeholder,
              onBlur,
              isDisabled,
              isPrefilled: !!value && !isTouched,
              dense,
              trackingForbidden,
              onValidEntry: onSelectChange,
              componentRef: ref,
            }}
            inputComponent={Address}
          />
        );
      }}
    />
  );
};

export default AddressQuestion;
