import { z } from 'zod';
import { CoverageKey, DeductibleType, InsurableInterest, LimitType, PolicyType, QuoteKind, QuotingStatus } from '../../types/quote.type';
import { CarrierSchema } from '../available-carriers/available-carriers.schema';

export const QuoteCoverageSchema = z.object({
  key: z.nativeEnum(CoverageKey),
  title: z.string(),
  description: z.string(),
  asset_gid: z.string().nullable(),
  limit_type: z.nativeEnum(LimitType).nullable(),
  limit_value: z.number().nullable(),
  limit_description: z.string().nullable(),
  deductible_type: z.nativeEnum(DeductibleType).nullable(),
  deductible_value: z.number().nullable(),
  deductible_description: z.string().nullable(),
  change_sources: z.record(z.string(), z.string()).and(z.record(z.literal('limit_value'), z.string())).optional(),
});

export const PaymentOptionSchema = z.object({
  term_months: z.number().nullish(),
  bundle: z.boolean().nullish(),
  downpayment: z.number().nullish(),
  subsequent_payment: z.number().nullish(),
  full_term_premium: z.string().nullish(),
});

export const QuoteCarrierSchema = CarrierSchema.extend({
  description: z.string(),
});

export const QuoteSchema = z.object({
  gid: z.string(),
  created_at: z.string(),
  kind: z.nativeEnum(QuoteKind),
  status: z.string(),
  state: z.string(),
  premium: z.number(),
  bundled_premium: z.number().nullable(),
  carrier: QuoteCarrierSchema,
  assets_gids: z.array(z.string()).nullable(),
  message: z.string().nullable(),
  policy_type: z.nativeEnum(PolicyType),
  requirement: z.string(),
  insurable_interest: z.nativeEnum(InsurableInterest),
  mpd_discount: z.number().nullable(),
  coverages: z.array(QuoteCoverageSchema).nullable(),
  coinsureds_gids: z.array(z.string()).nullable(),
  effective_date: z.string().datetime({ offset: true }).nullable(),
  payment_options: z.array(PaymentOptionSchema).nullable(),
  bundle: z.boolean().nullish(), // is bundle with same carrier
  multi_policy_discount_requested: z.boolean().nullish(),
  order: z.number().nullish(),
  appointed: z.boolean(),
});

export const SuggestedQuoteResponseSchema = z.object({
  quote: QuoteSchema.nullable(),
  // TODO: Refactor this after MVP. Maybe use a group pattern.
  auto_quote: z.object({ quote: QuoteSchema }).nullable(),
});

export const QuotesResponseSchema = z.object({
  quotes: z.array(QuoteSchema),
  quotes_request_status: z.nativeEnum(QuotingStatus),
});
